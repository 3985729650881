export default [
    {
      title: 'Home',
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: 'Spec Catalogue',
      route: 'catalogue',
      icon: 'UilBooks',
    },
    {
        title: 'Addition Catalogue',
        route: 'catalogue-addition',
        icon: 'UilAbacus',
    },
    {
      title: 'Opportunities',
      route: 'opportunities',
      icon: 'UilSuitcase',
    },  
    {
      title: 'Active Projects',
      route: 'active',
      icon: 'UilBuilding',
    },
    {
      title: 'Completed Projects',
      route: 'completed',
      icon: 'UilCheckCircle',
    }
  ]
  
<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
    class="nav-item"
  >
    <b-link class="d-flex align-items-center" v-bind="linkProps">
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->

      <uil-create-dashboard
        v-if="item.icon === 'UilCreateDashboard'"
        class="logo"
        size="180px"
      />
      <uil-chair v-if="item.icon === 'UilChair'" class="logo" size="180px" />
      <uil-tape v-if="item.icon === 'UilTape'" class="logo" size="180px" />
      <uil-wifi v-if="item.icon === 'UilWifi'" class="logo" size="180px" />
      <uil-constructor v-if="item.icon === 'UilConstructor'" class="logo" size="180px" />
      <uil-bill v-if="item.icon === 'UilBill'" class="logo" size="180px" />
      <uil-building v-if="item.icon === 'UilBuilding'" class="logo" size="180px" />
      <uil-angle-left v-if="item.icon === 'UilAngleLeft'" class="logo" size="180px" />
      <uil-check-circle v-if="item.icon === 'UilCheckCircle'" class="logo" size="180px" />
      <feather-icon v-if="item.icon === 'HomeIcon'" :icon="item.icon || 'CircleIcon'" />
      <!--      <uil-home  class="logo" v-if="item.icon == 'UilHome'"/>-->
      <uil-suitcase v-if="item.icon === 'UilSuitcase'" class="logo" size="180px" />
      <uil-dollar-alt v-if="item.icon === 'UilDollarAlt'" class="logo" size="180px" />
      <uil-books v-if="item.icon === 'UilBooks'" class="logo" size="180px" />
      <uil-abacus v-if="item.icon === 'UilAbacus'" class="logo" size="180px" />

      <span class="menu-title text-truncate" style="font-size: 15px">{{
        item.title
      }}</span>
      <b-badge
        v-if="item.tag"
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
        pill
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <svg
      v-if="item.icon === 'UilAbacu' +
       's'"
      style="margin: 25px 40%;"
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C8.60444 0 8.21776 0.117298 7.88886 0.337061C7.55996 0.556824 7.30362 0.869181 7.15224 1.23463C7.00087 1.60009 6.96126 2.00222 7.03843 2.39018C7.1156 2.77814 7.30608 3.13451 7.58579 3.41421C7.86549 3.69392 8.22186 3.8844 8.60982 3.96157C8.99778 4.03874 9.39992 3.99913 9.76537 3.84776C10.1308 3.69638 10.4432 3.44004 10.6629 3.11114C10.8827 2.78224 11 2.39556 11 2C11 1.46957 10.7893 0.96086 10.4142 0.585787C10.0391 0.210714 9.53043 0 9 0ZM2 0C1.60444 0 1.21776 0.117298 0.88886 0.337061C0.559962 0.556824 0.303617 0.869181 0.152242 1.23463C0.000866562 1.60009 -0.0387401 2.00222 0.0384303 2.39018C0.115601 2.77814 0.306082 3.13451 0.585787 3.41421C0.865492 3.69392 1.22186 3.8844 1.60982 3.96157C1.99778 4.03874 2.39992 3.99913 2.76537 3.84776C3.13082 3.69638 3.44318 3.44004 3.66294 3.11114C3.8827 2.78224 4 2.39556 4 2C4 1.46957 3.78929 0.96086 3.41421 0.585787C3.03914 0.210714 2.53043 0 2 0ZM16 0C15.6044 0 15.2178 0.117298 14.8889 0.337061C14.56 0.556824 14.3036 0.869181 14.1522 1.23463C14.0009 1.60009 13.9613 2.00222 14.0384 2.39018C14.1156 2.77814 14.3061 3.13451 14.5858 3.41421C14.8655 3.69392 15.2219 3.8844 15.6098 3.96157C15.9978 4.03874 16.3999 3.99913 16.7654 3.84776C17.1308 3.69638 17.4432 3.44004 17.6629 3.11114C17.8827 2.78224 18 2.39556 18 2C18 1.46957 17.7893 0.96086 17.4142 0.585787C17.0391 0.210714 16.5304 0 16 0Z"
        fill="#6E6B7B"
      />
    </svg>

    <b-dropdown-divider class="mx-1" v-if="item.title == 'AV / IT'"></b-dropdown-divider>
  </li>
</template>

<script>
import {
  UilBill,
  UilCreateDashboard,
  UilSuitcase,
  UilConstructor,
  UilWifi,
  UilTape,
  UilAngleLeft,
  UilBuilding,
  UilDollarAlt,
  UilCheckCircle,
  UilBooks,
  UilAbacus
} from "@iconscout/vue-unicons";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BBadge, BLink, BDropdownDivider } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import UilChair from "@/assets/CustomIcons/uil-chair";

export default {
  components: {
    BLink,
    BBadge,
    UilCreateDashboard,
    UilBill,
    UilSuitcase,
    UilConstructor,
    UilChair,
    UilWifi,
    UilTape,
    UilAngleLeft,
    UilBuilding,
    UilDollarAlt,
    UilCheckCircle,
    BDropdownDivider,
    UilBooks,
    UilAbacus
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>

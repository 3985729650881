<template>
  <div>
    <h1 style="margin-top: 10px"><b>Construction</b></h1>
    <p v-if="signed">Construction breakdown for all approved project quotes.</p>
    <!-- <b-tabs>
      <b-tab title="All Construction Items" class="printable"> -->
    <div>
      <b-card>
        <b-row>
          <b-col cols="3">
            <div class="my-1 mx-1">
              <p class="card_title">Total Construction COGS</p>
              <h2>
                {{
                  Number(constructionTotalCost()).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h2>
            </div>
          </b-col>
          <b-col cols="3" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total COGS / SQFT</p>
              <h3>
                {{
                  Number(
                    constructionTotalCost() / quote.sqft +
                      parseFloat(caTotalCostSqft()) +
                      parseFloat(COsqft()) +
                      parseFloat(ASUsqft().toFixed(2))
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
          <b-col cols="3" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total Construction Price</p>
              <h3>
                {{
                  Number(constructionTotalPrice).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
          <b-col cols="3" class="d-flex">
            <hr class="title_divider" />
            <div class="my-1 mx-1">
              <p class="card_title">Total Construction Price / SQFT</p>
              <h3>
                {{
                  Number(constructionTotalPrice / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="invoice-preview-card">
        <b-card-body>
          <b-row>
            <b-col cols="12">
              <h3 class="mt-2" style="color: #636363"><b>Base Spec</b></h3>
            </b-col>
            <b-col cols="12">
              <v-simple-table v-if="quote.construction_spec_quote.length > 0">
                <thead>
                  <b-tr>
                    <b-th class="table-background-color"> specification</b-th>
                    <b-th class="table-background-color"> tender code </b-th>
                    <b-th class="table-background-color"> COGS / Unit </b-th>
                    <b-th class="table-background-color"> Price / Unit </b-th>
                    <b-th class="table-background-color"> Quantity </b-th>
                    <b-th class="table-background-color"> Total COGS </b-th>
                    <b-th class="table-background-color"> Total COGS/sqft </b-th>
                    <b-th class="table-background-color"> Total price </b-th>
                    <b-th class="table-background-color"> Total price/sqft </b-th>
                  </b-tr>
                </thead>
                <tbody
                  v-for="name in quote.base_spec_version < 3.5
                    ? sortingArr
                    : sortingArr_v2"
                  :key="name + 'body'"
                >
                  <b-tr>
                    <b-th class="table-background-spec" colspan="9">{{ name }} </b-th>
                  </b-tr>

                  <template v-for="construction_spec in constructtionSpecFiltered(name)">
                    <b-tr class="border-group-details" :key="construction_spec.id">
                      <b-td style="font-weight: 600; font-size: 14px; line-height: 21px">
                        <div
                          v-if="
                            constructionAdjustments(
                              construction_spec.construction_work_id
                            ).length != 0
                          "
                          class="d-flex"
                        >
                          <uil-arrow-growth
                            style="color: #ff9f43; margin-top: 5px"
                            class="mr-1"
                          />
                          <div
                            v-for="construction_adjustment in constructionAdjustments(
                              construction_spec.construction_work_id
                            )"
                            :key="construction_adjustment + 'row_adjusted_name'"
                          >
                            <div v-if="construction_adjustment.name != ''">
                              {{ construction_adjustment.name }}
                            </div>
                            <div v-else>{{ construction_spec.name }}</div>
                          </div>
                        </div>
                        <div v-else>
                          {{ construction_spec.name }}
                        </div>
                      </b-td>
                      <b-td>
                        {{ searchUniformatCodeName(construction_spec.uniformat) }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            Number(construction_spec.unit_cost) +
                              constructionAdjustmentTotalUnitCost(
                                construction_spec.construction_work_id
                              )
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            Number(construction_spec.unit_price) +
                              constructionAdjustmentTotalUnitPrice(
                                construction_spec.construction_work_id
                              )
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            Number(construction_spec.qty) +
                              constructionAdjustmentTotalQuantity(
                                construction_spec.construction_work_id
                              )
                          ) +
                          " " +
                          construction_spec.unit_type
                        }}
                      </b-td>
                      <b-td>
                        {{
                          "" +
                          Number(
                            construction_spec.total_cost +
                              constructionAdjustmentTotalCost(
                                construction_spec.construction_work_id
                              )
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          "" +
                          Number(
                            Number(construction_spec.cost_per_sqft) +
                              constructionAdjustmentTotalCostPerSqft(
                                construction_spec.construction_work_id
                              )
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            constructionAdjustmentTotalPrice(
                              construction_spec.construction_work_id
                            ) +
                              Number(construction_spec.unit_price) *
                                Number(construction_spec.qty) +
                              Number(contingencyFeeCalc(construction_spec))
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            (constructionAdjustmentTotalPrice(
                              construction_spec.construction_work_id
                            ) +
                              Number(construction_spec.unit_price) *
                                Number(construction_spec.qty)) /
                              quote.sqft
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                    </b-tr>
                    <template
                      v-if="
                        constructionAdjustments(construction_spec.construction_work_id)
                          .length != 0
                      "
                    >
                      <b-tr
                        style="background: #f8f8f8"
                        class="border-group-details"
                        :key="'base-spec' + construction_spec.id"
                      >
                        <b-td
                          colspan="2"
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          Base Spec
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(construction_spec.unit_cost).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(construction_spec.unit_price).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{ construction_spec.qty + " " + construction_spec.unit_type }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(construction_spec.total_cost).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(construction_spec.cost_per_sqft).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(
                              construction_spec.qty * Number(construction_spec.unit_price)
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </b-td>
                        <b-td class="muted-base-spec">
                          {{
                            Number(
                              (construction_spec.qty *
                                Number(construction_spec.unit_price)) /
                                quote.sqft
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          }}
                        </b-td>
                      </b-tr>
                      <b-tr
                        style="background: #f8f8f8"
                        class="border-group-details"
                        :key="
                          'adjustment' +
                          construction_spec.construction_work_id +
                          construction_adjustment.id
                        "
                        v-for="construction_adjustment in constructionAdjustments(
                          construction_spec.construction_work_id
                        )"
                      >
                        <b-td
                          colspan="2"
                          style="
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            width: 50%;
                          "
                        >
                          <div v-if="construction_adjustment.is_spec_swap">
                            <div style="font-weight: 700">
                              {{ construction_adjustment.name }}
                            </div>
                            <div>
                              {{ construction_adjustment.details }}
                            </div>
                          </div>
                          <div class="text-muted">
                            {{ construction_adjustment.internal_note }}
                          </div>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              construction_adjustment.unit_cost > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up v-if="construction_adjustment.unit_cost > 0" />
                            <uil-arrow-down
                              v-else-if="construction_adjustment.unit_cost < 0"
                            />{{
                              construction_adjustment.unit_cost != 0
                                ? "" +
                                  Number(
                                    Math.abs(construction_adjustment.unit_cost)
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              construction_adjustment.unit_price > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up v-if="construction_adjustment.unit_price > 0" />
                            <uil-arrow-down
                              v-else-if="construction_adjustment.unit_price < 0"
                            />{{
                              construction_adjustment.unit_price != 0
                                ? "" +
                                  Number(
                                    Math.abs(construction_adjustment.unit_price)
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              construction_adjustment.quantity > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up v-if="construction_adjustment.quantity > 0" />
                            <uil-arrow-down
                              v-else-if="construction_adjustment.quantity < 0"
                            />{{
                              construction_adjustment.quantity != 0
                                ? Number(
                                    Math.abs(construction_adjustment.quantity)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  }) +
                                  " " +
                                  construction_spec.unit_type
                                : ""
                            }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              construction_adjustment.total_cost > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up v-if="construction_adjustment.total_cost > 0" />
                            <uil-arrow-down
                              v-else-if="construction_adjustment.total_cost < 0"
                            />{{
                              construction_adjustment.total_cost != 0
                                ? "" +
                                  Number(
                                    Math.abs(construction_adjustment.total_cost)
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              construction_adjustment.cost_per_sqft > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up
                              v-if="construction_adjustment.cost_per_sqft > 0"
                            />
                            <uil-arrow-down
                              v-else-if="construction_adjustment.cost_per_sqft < 0"
                            />{{
                              construction_adjustment.cost_per_sqft != 0
                                ? "" +
                                  Number(
                                    Math.abs(construction_adjustment.cost_per_sqft)
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              Number(construction_adjustment.total_price) > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up
                              v-if="Number(construction_adjustment.total_price) > 0"
                            />
                            <uil-arrow-down
                              v-else-if="Number(construction_adjustment.total_price) < 0"
                            />{{
                              Number(construction_adjustment.total_price) != 0
                                ? "" +
                                  Number(
                                    Math.abs(Number(construction_adjustment.total_price))
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                            {{ construction_adjustment.qty }}
                          </span>
                        </b-td>
                        <b-td
                          style="font-weight: 400; font-size: 12px; line-height: 18px"
                        >
                          <span
                            style="white-space: nowrap"
                            :class="
                              Number(
                                Number(construction_adjustment.total_price) / quote.sqft
                              ) > 0
                                ? 'increase'
                                : 'decrease'
                            "
                          >
                            <uil-arrow-up
                              v-if="
                                Number(
                                  Number(construction_adjustment.total_price) / quote.sqft
                                ) > 0
                              "
                            />
                            <uil-arrow-down
                              v-else-if="
                                Number(
                                  Number(construction_adjustment.total_price) / quote.sqft
                                ) < 0
                              "
                            />{{
                              Number(
                                Number(construction_adjustment.total_price) / quote.sqft
                              ) != 0
                                ? "" +
                                  Number(
                                    Math.abs(
                                      Number(
                                        Number(construction_adjustment.total_price) /
                                          quote.sqft
                                      )
                                    )
                                  ).toLocaleString("en-CA", {
                                    minimumFractionDigits: 2,
                                    style: "currency",
                                    currency: "CAD",
                                  })
                                : ""
                            }}
                          </span>
                        </b-td>
                      </b-tr>
                    </template>
                  </template>
                </tbody>
                <template v-if="constructionManagementFee.length > 0">
                  <tbody>
                    <b-tr>
                      <b-th class="table-background-spec" colspan="9"
                        >Construction Management Fee</b-th
                      >
                    </b-tr>
                    <b-tr
                      class="border-group-details"
                      v-for="construction_spec in constructionManagementFee"
                      :key="construction_spec.id"
                    >
                      <b-td style="font-weight: 600; font-size: 14px; line-height: 21px">
                        {{ construction_spec.name }}
                      </b-td>
                      <b-td>
                        {{ searchUniformatCodeName(construction_spec.uniformat) }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            Number(construction_spec.unit_cost) * 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          }) + "%"
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            Number(construction_spec.unit_price) * 100
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          }) + "%"
                        }}
                      </b-td>
                      <b-td> - </b-td>
                      <b-td>
                        {{
                          Number(construction_spec.total_cost).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(construction_spec.cost_per_sqft).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                          )
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(construction_spec.total_price).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                      <b-td>
                        {{
                          Number(
                            (construction_spec.total_price * 1) / quote.sqft
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </b-td>
                    </b-tr>
                  </tbody>
                </template>
                <b-tr class="border-group-details" v-if="cmFeeCalc > 0">
                  <td style="font-weight: 600; font-size: 14px; line-height: 21px">
                    <p style="margin: 12px; margin-left: 15px">CM Fee</p>
                  </td>
                  <td>
                    <div class="mx-1">
                      {{ searchUniformatCodeName("Z1010.10") }}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="mx-1"></div>
                  </td>
                  <td>
                    {{
                      Number(cmFeeCalc).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      Number(Number(cmFeeCalc) / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </b-tr>
                <b-tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <td>
                    <p style="margin: 12px; margin-left: 15px">TOTAL</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="mx-1">
                      {{
                        Number(totalConstruction.toFixed(2)).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </div>
                  </td>
                  <td>
                    <div class="mx-1">
                      {{
                        Number(
                          (
                            totalConstructionSqft +
                            quote.options_construction_cost_per_sqft
                          ).toFixed(2)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </div>
                  </td>
                  <td>
                    {{
                      Number(totalConstructionPrice + cmFeeCalc).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      Number(
                        Number(totalConstructionPrice + cmFeeCalc) / quote.sqft
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </td>
                </b-tr>
              </v-simple-table>
              <div v-else>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no base spec items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card v-if="SCItems.length > 0">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b>Site Conditions</b>
                </h3>
              </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="2" class="my-1 pl-0" align-self="end" style="text-align: end">
                <b-form-input
                  v-if="SCItems.length > 0"
                  v-model="SCsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  class="my-1 mr-2"
                ></b-form-input>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="SCItems.length > 0"
              :headers="SCheaders"
              :items="SCItems"
              item-key="id"
              ref="myTable"
              @current-items="SCcurrentItems"
              :disable-pagination="true"
              :hide-default-footer="true"
              :search="SCsearch"
            >
              <template v-for="(col, i) in SCfilters" v-slot:[`header.${i}`]="{ header }">
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="attachIdSC"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#attachIdSC"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            SCactiveFilters[header.value] &&
                            SCactiveFilters[header.value].length <
                              SCfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in SCfilters[header.value]">
                          <v-list-item :key="`${item}`" :value="item" :ripple="false">
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="SCactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          block
                          @click="SCtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Select all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          block
                          @click="SCclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Deselect all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.details
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                {{
                  Number(item.cost_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                {{
                  Number(item.total_delivery_install.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price="{ item }">
                {{
                  Number(item.total_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.price_per_sqft="{ item }">
                {{
                  Number(item.price_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="SCtotalColumnShow('item')"></th>
                  <th v-if="SCtotalColumnShow('internal_note')"></th>
                  <th v-if="SCtotalColumnShow('uniformat')"></th>
                  <th v-if="SCtotalColumnShow('total_cost')">
                    {{
                      Number(SCsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="SCtotalColumnShow('cost_per_sqft')">
                    {{
                      Number(SCsumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="SCtotalColumnShow('total_price')">
                    {{
                      Number(SCsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="SCtotalColumnShow('price_per_sqft')">
                    {{
                      Number(SCsumField("price_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional additional scope & upgrades items to show for
                  this
                  <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b v-if="Number(quote.base_spec_version) >= 3"
                    >Base Contract Additions</b
                  >
                  <b v-else> Additional Scope & Upgrades</b>
                </h3>
                <p class="text-muted" v-if="Number(quote.base_spec_version) >= 3">
                  Base Contract Additions are the prices for materials and work above that
                  included in the Base Contract and Construction Specifications. These
                  prices are eligible for a fixed price contact when executed with a CCDC2
                  or CCDC14.
                </p>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="ASUItems.length > 0"
                  v-model="ASUsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  style="margin-top: 15px"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="my-1 pl-0" style="margin-top: 15px">
                <div
                  v-if="ASUItems.length > 0"
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ ASUSelectedAll }}
                          <b-form-checkbox
                            v-model="ASUallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="ASUtoggleAllDrop"
                          >
                            {{ ASUallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider class="my-0 py-0"
                        /></template>

                        <b-form-checkbox
                          v-model="ASUdisplayColumnsCheck"
                          v-for="(f, index) in ASUfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox></b-form-group
                      >
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          @click="resetCols('asu')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="ASUItems.length > 0"
              :headers="ASUheaders"
              :items="ASUItems"
              item-key="id"
              ref="myTable"
              @current-items="ASUcurrentItems"
              :disable-pagination="true"
              :hide-default-footer="true"
              :search="ASUsearch"
            >
              <template
                v-for="(col, i) in ASUfilters"
                v-slot:[`header.${i}`]="{ header }"
              >
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="attachId"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#attachId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            ASUactiveFilters[header.value] &&
                            ASUactiveFilters[header.value].length <
                              ASUfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in ASUfilters[header.value]">
                          <v-list-item :key="`${item}`" :value="item" :ripple="false">
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="ASUactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="ASUtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Select all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="ASUclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Deselect all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.details
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                {{
                  Number(item.cost_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                {{
                  Number(item.total_delivery_install.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price="{ item }">
                {{
                  Number(item.total_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.price_per_sqft="{ item }">
                {{
                  Number(item.price_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="ASUtotalColumnShow('item')"></th>
                  <th v-if="ASUtotalColumnShow('internal_note')"></th>
                  <th v-if="ASUtotalColumnShow('uniformat')"></th>
                  <th v-if="ASUtotalColumnShow('total_cost')">
                    {{
                      Number(ASUsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('cost_per_sqft')">
                    {{
                      Number(ASUsumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('total_price')">
                    {{
                      Number(ASUsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="ASUtotalColumnShow('price_per_sqft')">
                    {{
                      Number(ASUsumField("price_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional additional scope & upgrades items to show for
                  this
                  <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- ? start CA  table-->
      <b-card v-if="CAItems.length > 0">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b>Cash Allowances</b>
                </h3>
                <p class="text-muted">
                  A cash allowance is an estimate for materials or work that is required,
                  but which cannot be adequately scoped or priced without further details.
                  When the materials or work can be accurately priced, per the CCDC, the
                  Contract Price shall be adjusted by Change Order to provide for any
                  difference between the cash allowance and the actual cost of the work
                  performed.
                </p>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="CAItems.length > 0"
                  v-model="CAsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  style="margin-top: 15px"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="mb-3 pl-0" style="margin-top: 15px">
                <div
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    v-if="CAItems.length > 0"
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ CASelectedAll }}
                          <b-form-checkbox
                            v-model="CAallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="CAtoggleAllDrop"
                          >
                            {{ CAallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider />
                        </template>
                        <b-form-checkbox
                          v-model="CAdisplayColumnsCheck"
                          v-for="(f, index) in CAfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          @click="resetCols('cash')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="CAItems.length > 0"
              :headers="CAheaders"
              :items="CAItems"
              item-key="id"
              ref="myTable"
              @current-items="CAcurrentItems"
              @click:row="clicked"
              :search="CAsearch"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template v-for="(col, i) in CAfilters" v-slot:[`header.${i}`]="{ header }">
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="CAattachId"
                  :key="'CAfilter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#CAattachId"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            CAactiveFilters[header.value] &&
                            CAactiveFilters[header.value].length <
                              CAfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in CAfilters[header.value]">
                          <v-list-item
                            :key="`${item}ca`"
                            :value="item"
                            :ripple="false"
                            v-if="item != null"
                          >
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="CAactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="CAtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Toggle all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="CAclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Clear all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              -->
              <!-- start formatted data and custom formulas
                  <template v-slot:item.name="{ item }">
                    <div class="mt-1">
                      <p>
                        {{ item.name }} <br /><small style="color: #b9b9c3">{{
                          item.details
                        }}</small>
                      </p>
                    </div>
                  </template>
                  <template v-slot:item.total_cost="{ item }">
                    {{
                      Number(Number(item.total_cost).toFixed(2)).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>

                  <template v-slot:item.cost_per_sqft="{ item }">
                    {{
                      Number(item.cost_per_sqft).toFixed(2).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>
                  <template v-slot:item.total_delivery_install="{ item }">
                    {{
                      Number(item.total_delivery_install.toFixed(2)).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </template>
                  <template v-slot:item.total_price="{ item }">
                    {{
                      Number(item.total_price).toFixed(2).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>
                  <template v-slot:item.price_per_sqft="{ item }">
                    {{
                      Number(item.price_per_sqft).toFixed(2).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </template>

                  <template v-slot:item.uniformat="{ item }">
                    {{ searchUniformatCodeName(item.uniformat) }}
                  </template>
                  <!-- end formatted data and custom formulas -->

              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->
              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="CAtotalColumnShow('item')"></th>
                  <th v-if="CAtotalColumnShow('internal_note')"></th>
                  <th v-if="CAtotalColumnShow('uniformat')"></th>
                  <th v-if="CAtotalColumnShow('quantity')"></th>
                  <th v-if="CAtotalColumnShow('cost_per_sqft')">
                    {{
                      Number(CAsumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('total_cost')">
                    {{
                      Number(CAsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('price_per_sqft')">
                    {{
                      Number(CAsumField("price_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="CAtotalColumnShow('total_price')">
                    {{
                      Number(CAsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional items to show for this <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional items to show for this <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- ? end CA table -->
      <b-card v-if="signed">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                <h3 class="mt-2" style="color: #636363">
                  <b>Change Orders</b>
                </h3>
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-if="COItems.length > 0"
                  v-model="COsearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  class="my-1 mr-2"
                ></b-form-input>
              </b-col>
              <b-col cols="2" class="my-1 pl-0" align-self="end" style="text-align: end">
                <div
                  v-if="COItems.length > 0"
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    text="Split Link"
                    checkbox-menu
                    allow-focus
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ COSelectedAll }}
                          <b-form-checkbox
                            v-model="COallSelected"
                            aria-describedby="flavours"
                            aria-controls="flavours"
                            @change="COtoggleAllDrop"
                          >
                            {{ COallSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                          <b-dropdown-divider class="my-0 py-0"
                        /></template>

                        <b-form-checkbox
                          v-model="COdisplayColumnsCheck"
                          v-for="(f, index) in COfields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox></b-form-group
                      >
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          @click="resetCols('co')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            <!-- :fixed-header="true" -->
            <v-data-table
              v-if="COItems.length > 0"
              :headers="COheaders"
              :items="COItems"
              item-key="id"
              ref="myTable"
              @current-items="COcurrentItems"
              :disable-pagination="true"
              :hide-default-footer="true"
              :search="COsearch"
            >
              <template v-for="(col, i) in COfilters" v-slot:[`header.${i}`]="{ header }">
                <div style="display: inline-block; padding: 16px 0" :key="i">
                  {{ header.text }}
                </div>
                <div
                  style="float: right; margin-top: 8px"
                  id="attachIdCO"
                  :key="'filter' + i"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :max-width="400"
                    :min-width="300"
                    bottom
                    left
                    attach="#attachIdCO"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :style="
                            COactiveFilters[header.value] &&
                            COactiveFilters[header.value].length <
                              COfilters[header.value].length
                              ? 'color:black'
                              : 'color:#dedede'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-0">
                      <v-list-item-group>
                        <template v-for="item in COfilters[header.value]">
                          <v-list-item
                            :key="`${item}`"
                            :value="item"
                            :ripple="false"
                            v-if="item != null"
                          >
                            <template>
                              <v-list-item-action>
                                <b-form-checkbox
                                  v-model="COactiveFilters[header.value]"
                                  :value="item"
                                ></b-form-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  style="font-weight: normal; text-transform: none"
                                  >{{
                                    item != null
                                      ? searchUniformatCodeName(item)
                                      : "No Tender Code"
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                    <v-row no-gutters style="background-color: #fff">
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="COtoggleAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Select all</b-button
                        >
                      </v-col>
                      <v-col cols="6">
                        <b-button
                          text
                          block
                          @click="COclearAll(header.value)"
                          variant="outline-light"
                          style="border-color: transparent; color: rgba(92, 177, 231, 1)"
                          >Deselect all</b-button
                        >
                      </v-col>
                    </v-row>
                  </v-menu>
                </div>
              </template>
              <!-- start formatted data and custom formulas -->
              <template v-slot:item.name="{ item }">
                <div class="mt-1">
                  <p>
                    {{ item.name }} <br /><small style="color: #b9b9c3">{{
                      item.details
                    }}</small>
                  </p>
                </div>
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.unit_cost="{ item }">
                {{
                  Number(item.unit_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                {{
                  Number(item.total_delivery_install.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price="{ item }">
                {{
                  Number(item.total_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.unit_price="{ item }">
                {{
                  Number(item.unit_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.uniformat="{ item }">
                {{ searchUniformatCodeName(item.uniformat) }}
              </template>
              <!-- end formatted data and custom formulas -->

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="COtotalColumnShow('internal_note')"></th>
                  <th v-if="COtotalColumnShow('uniformat')"></th>
                  <th v-if="COtotalColumnShow('quantity')">
                    {{ Number(COsumField("quantity")) }}
                  </th>
                  <th v-if="COtotalColumnShow('unit_cost')">
                    {{
                      Number(COsumField("unit_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('total_cost')">
                    {{
                      Number(COsumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('unit_price')">
                    {{
                      Number(COsumField("unit_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="COtotalColumnShow('total_price')">
                    {{
                      Number(COsumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                </tr>
              </template>
              <!-- start no data slot -->
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->

              <!-- start no data slot -->
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional scope & upgrades items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              <!-- end no data slot -->
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional additional scope & upgrades items to show for
                  this
                  <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <!-- </b-tab>
      <b-tab title="Tender Breakdown" class="printable">
        <b-card>
          <div class="mx-2 mt-1">
            <b-row>
              <b-col cols="10">
                <h2>Construction Tender</h2>
                <p class="grey_text" style="font-weight: 400">
                  Tender data for all approved quotes
                </p></b-col
              >
              <b-col cols="2">
                <b-button
                  @click="exportTender"
                  class="w-100"
                  style="padding: 15px; margin-top: 7px"
                  variant="outline-secondary"
                >
                  <uil-file-export size="15" /> &nbsp; Export
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-tab>
    </b-tabs> -->
  </div>
</template>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BFormInput,
  BFormCheckbox,
  BDropdownForm,
  BDropdown,
  BButton,
  BFormGroup,
  BDropdownDivider,
  BCardBody,
  BTable,
  BDropdownItem,
  BCardTitle,
  BSpinner,
  BTableSimple,
  BTr,
  BTh,
  BThead,
  BTbody,
  BTd,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  UilExclamationTriangle,
  UilColumns,
  UilArrowGrowth,
  UilArrowUp,
  UilArrowDown,
  UilFileExport,
} from "@iconscout/vue-unicons";
import {
  VIcon,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VDivider,
  VListItemGroup,
  VRow,
  VCheckbox,
  VSimpleTable,
} from "vuetify/lib";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import { forEach } from "postcss-rtl/lib/affected-props";
import tenderCodes from "@/assets/objects/tender-codes.json";
import uniformat from "@/assets/objects/uniformat-codes.json";

export default {
  name: "ReviewConstructionSpecSection",
  components: {
    BDropdownForm,

    BFormGroup,
    BDropdownDivider,
    BAlert,
    BFormInput,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    BButton,
    BCardTitle,
    BSpinner,
    JsonExcel,
    BTableSimple,
    BTr,
    BTh,
    BThead,
    BTbody,
    BTd,
    UilExclamationTriangle,
    UilColumns,
    VIcon,
    VMenu,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox,
    VSimpleTable,
    UilArrowGrowth,
    UilArrowUp,
    UilArrowDown,
    UilFileExport,
    BTabs,
    BTab,
  },
  data() {
    return {
      // * uniformat code object
      uniformat: uniformat,
      breakdownFields: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "qty",
          label: "QTY",
          sortable: false,
        },
        {
          key: "total_cost",
          label: "Cost",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "$/sqft",
          sortable: true,
        },
      ],
      siteConditionsPerPage: 15,
      siteConditionsCurrentPage: 1,
      sortingArr: [
        "Drywall",
        "Glazing",
        "Doors",
        "Flooring",
        "Electrical (by Space)",
        "Electrical (Takeoff)",
        "Lighting",
        "Mechanical",
        "Kitchen & Millwork",
        "Wall Finishes",
        "Closeout & Other",
        "Suite/General Conditions",
        "General Requirements",
      ],
      sortingArr_v2: [
        "Drywall",
        "Glazing",
        "Doors",
        "Flooring",
        "Electrical",
        "Lighting",
        "Mechanical",
        "Kitchen & Millwork",
        "Wall Finishes",
        "Closeout & Other",
        "Suite/General Conditions",
        "General Requirements",
      ],
      // start AS&U variables
      ASUItems: [],
      ASUsearch: "",
      ASUfilters: {
        uniformat: [],
      },
      ASUallSelected: false,
      ASUfilteredItems: [],
      ASUactiveFilters: {},
      ASUfields: [
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Total COGS/sqft",
          key: "cost_per_sqft",
        },
        {
          label: "Total Price",
          key: "total_price",
        },
        {
          label: "Total Price/SQFT",
          key: "price_per_sqft",
        },
      ],
      ASUdisplayColumnsCheck: [
        "name",
        "internal_note",
        "uniformat",
        "total_cost",
        "cost_per_sqft",
      ],
      ASUtotalRows: 0,
      // start SiteConditions variables
      SCItems: [],
      SCsearch: "",
      SCfilters: {
        uniformat: [],
      },
      SCallSelected: false,
      SCfilteredItems: [],
      SCactiveFilters: {},
      SCfields: [
        {
          label: "Internal note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Total COGS/sqft",
          key: "cost_per_sqft",
        },
        {
          label: "Total Price",
          key: "total_price",
        },
        {
          label: "Total Price/SQFT",
          key: "price_per_sqft",
        },
      ],
      SCdisplayColumnsCheck: [
        "name",
        "internal_note",
        "uniformat",
        "unit_cost",
        "total_cost",
        "cost_per_sqft",
      ],
      SCtotalRows: 0,
      // start Change Orders variables
      COItems: [],
      COsearch: "",
      COfilters: {
        uniformat: [],
      },
      COallSelected: false,
      COfilteredItems: [],
      COactiveFilters: {},
      COfields: [
        {
          label: "Internal note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "Quantity",
          key: "quantity",
        },
        {
          label: "COGS / Unit",
          key: "unit_cost",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Price / Unit",
          key: "unit_price",
        },
        {
          label: "Total Price",
          key: "total_price",
        },
      ],
      COdisplayColumnsCheck: [
        "name",
        "internal_note",
        "uniformat",
        "quantity",
        "unit_cost",
        "total_cost",
      ],
      COtotalRows: 0,
      // start CA variables
      CAItems: [],
      CAsearch: "",
      CAfilters: {
        uniformat: [],
      },
      CAfilteredItems: [],
      CAactiveFilters: {},
      CAfields: [
        {
          label: "Internal Note",
          key: "internal_note",
        },
        {
          label: "Tender Code",
          key: "uniformat",
        },
        {
          label: "COGS / SQFT",
          key: "cost_per_sqft",
        },
        {
          label: "Total COGS",
          key: "total_cost",
        },
        {
          label: "Price / SQFT",
          key: "price_per_sqft",
        },
        {
          label: "Total Price",
          key: "total_price",
        },
      ],
      CAdisplayColumnsCheck: [
        "name",
        "internal_note",
        "uniformat",
        "total_cost",
        "total_price",
      ],
      CAtotalRows: 0,
      // get codes from uniformat-codes-with-categories.json
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
    };
  },
  props: ["quote", "change_orders", "signed", "address"],
  computed: {
    totalConstruction() {
      let results = 0;
      results += this.quote.construction_spec_quote.reduce(
        (total, obj) => obj.total_cost + total,
        0
      );
      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          results += parseFloat(e.total_cost);
        });
      return results;
    },
    totalConstructionPrice() {
      let results = 0;
      results += this.quote.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
        0
      );
      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          results += parseFloat(e.total_price);
        });

      this.quote.options
        .filter(
          (n) =>
            n.is_base_spec == true &&
            !n.is_spec_swap &&
            !n.is_adjustment &&
            n.budget_group == "Construction"
        )
        .forEach((e) => {
          results +=
            this.quote.base_spec_version > 3.6
              ? Number(
                  Number(e.total_cost) *
                    (Number(this.quote.contingency_fee_percentage) / 100)
                )
              : 0;
        });

      return results;
    },
    totalConstructionSqft() {
      const results = this.quote.construction_spec_quote.reduce(
        (total, obj) => parseFloat(obj.cost_per_sqft) + total,
        0
      );
      return results;
    },
    constructionManagementFee() {
      return this.quote.construction_spec_quote.filter(
        (n) => n.category == "Construction Management Fee"
      );
    },
    ASUSelectedAll() {
      if (this.ASUdisplayColumnsCheck.length < 7) {
        this.ASUallSelected = false;
      } else {
        this.ASUallSelected = true;
      }
    },
    COSelectedAll() {
      if (this.ASUdisplayColumnsCheck.length < 6) {
        this.COallSelected = false;
      } else {
        this.COallSelected = true;
      }
    },
    CASelectedAll() {
      if (this.CAdisplayColumnsCheck.length <= 6) {
        this.CAallSelected = false;
      } else {
        this.CAallSelected = true;
      }
    },
    constructionTotalPrice() {
      var sum = 0;
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + this.contingencyFeeCalc(e);
      });
      this.quote.options
        .filter((n) => n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      this.quote.options
        .filter(
          (n) =>
            n.is_base_spec == true &&
            !n.is_spec_swap &&
            !n.is_adjustment &&
            n.budget_group == "Construction"
        )
        .forEach((e) => {
          sum +=
            this.quote.base_spec_version > 3.6
              ? Number(
                  Number(e.total_cost) *
                    (Number(this.quote.contingency_fee_percentage) / 100)
                )
              : 0;
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });

      this.quote.allowances
        .filter((n) => n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      if (this.signed && this.COItems.length > 0) {
        this.COItems.forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      }
      sum += this.cmFeeCalc * 1;
      return Number(sum).toFixed(2);
    },

    ASUheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          sortable: false,
          filter: (value) => {
            return this.ASUactiveFilters.uniformat
              ? this.ASUactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Total COGS/sqft",
          value: "cost_per_sqft",
        },
        {
          text: "Total Price",
          value: "total_price",
        },
        {
          text: "Total Price/SQFT",
          value: "price_per_sqft",
        },
      ].filter((s) => this.ASUdisplayColumnsCheck.includes(s.value));
    },
    SCheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          sortable: false,
          filter: (value) => {
            return this.SCactiveFilters.uniformat
              ? this.SCactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Total COGS/sqft",
          value: "cost_per_sqft",
        },
        {
          text: "Total Price",
          value: "total_price",
        },
        {
          text: "Total Price/SQFT",
          value: "price_per_sqft",
        },
      ].filter((s) => this.SCdisplayColumnsCheck.includes(s.value));
    },
    COheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },
        {
          text: "Internal note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          sortable: false,
          filter: (value) => {
            return this.COactiveFilters.uniformat
              ? this.COactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "COGS / Unit",
          value: "unit_cost",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Price / Unit",
          value: "unit_price",
        },
        {
          text: "Total Price",
          value: "total_price",
        },
      ].filter((s) => this.COdisplayColumnsCheck.includes(s.value));
    },
    // CA headers
    CAheaders() {
      return [
        {
          text: "Item",
          value: "name",
          width: "20%",
        },

        {
          text: "Internal Note",
          value: "internal_note",
          width: "30%",
        },
        {
          text: "Tender Code",
          value: "uniformat",
          sortable: false,
          filter: (value) => {
            return this.CAactiveFilters.uniformat
              ? this.CAactiveFilters.uniformat.includes(value)
              : false;
          },
        },
        {
          text: "COGS / SQFT",
          value: "cost_per_sqft",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Price / SQFT",
          value: "price_per_sqft",
        },
        {
          text: "Total Price",
          value: "total_price",
        },
      ].filter((s) => this.CAdisplayColumnsCheck.includes(s.value));
    },
    // methods to calc cm fee is its activeated
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },
  },
  mounted() {
    this.updateBreadcrumb();
    this.initFilters();
  },
  methods: {
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[1].text = `${this.address}`;
      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[2].text = `Construction `;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    initFilters() {
      // init ffe items
      this.ASUItems = this.quote.options.filter(
        (o) => o.budget_group == "Construction" && o.is_base_spec == false
      );
      this.SCItems = this.quote.site_conditions.filter(
        (o) => o.budget_group == "Construction"
      );
      this.CAItems = this.quote.allowances.filter(
        (o) => o.budget_group == "Construction"
      );

      // init CO items
      let coApproved = this.change_orders.filter((c) => c.status == "Approved");
      if (coApproved.length > 0) {
        coApproved.map((co) => {
          if (co.change_order_lines.length > 0) {
            co.change_order_lines.map((l) => {
              if (l.budget_group == "Construction") {
                this.COItems.push(l);
              }
            });
          }
        });
      }
      let col = "";
      for (col in this.ASUfilters) {
        this.ASUfilters[col] = this.ASUItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      // init Site cond filters
      let col2 = "";
      for (col2 in this.SCfilters) {
        this.SCfilters[col] = this.quote.site_conditions
          .map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      // Init CO filters
      let col3 = "";
      for (col3 in this.COfilters) {
        this.COfilters[col] = this.COItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      //init CA filters
      let col4 = "";
      for (col4 in this.CAfilters) {
        this.CAfilters[col] = this.CAItems.map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      this.ASUactiveFilters = Object.assign({}, this.ASUfilters);
      this.SCactiveFilters = Object.assign({}, this.SCfilters);
      this.COactiveFilters = Object.assign({}, this.COfilters);
      this.CAactiveFilters = Object.assign({}, this.CAfilters);
    },
    constructtionSpecFiltered(name) {
      return this.quote.construction_spec_quote.filter((n) => n.category == name);
    },
    constructionAdjustments(constructionWorkId) {
      return this.quote.options.filter(
        (n) => n.construction_work_id == constructionWorkId
      );
    },
    constructionAdjustmentTotalUnitCost(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.unit_cost);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalUnitPrice(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.unit_price);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalPrice(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalCost(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalCostPerSqft(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.cost_per_sqft);
        });
      return parseFloat(sum);
    },
    constructionTotalCost() {
      var sum = 0;
      this.quote.construction_spec_quote.map((e) => {
        sum += parseFloat(e.total_cost);
      });
      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });
      sum += this.SCItems.reduce((total, obj) => obj.total_cost + total, 0);
      sum += this.ASUItems.reduce((total, obj) => obj.total_cost + total, 0);
      sum += this.CAItems.reduce((total, obj) => obj.total_cost * 1 + total, 0);
      // this.quote.site_conditions
      //   .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
      //   .forEach((e) => {
      //     sum += parseFloat(e.total_cost);
      //   });
      if (this.COItems.length > 0 && this.signed) {
        sum += this.COItems.reduce((total, obj) => obj.total_cost + total, 0);
      }

      return Number(sum).toFixed(2);
    },
    caTotalCostSqft() {
      let result = this.CAItems.reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return Number(result) / Number(this.quote.sqft);
    },
    COsqft() {
      let result = 0;
      if (this.change_orders.length > 0 && this.signed) {
        result =
          this.change_orders
            .filter((c) => c.status === "Approved")
            .flatMap((co) =>
              co.change_order_lines.filter((l) => l.budget_group === "Construction")
            )
            .reduce((sum, l) => sum + parseFloat(l.total_cost), 0) / this.quote.sqft;
      }
      return result.toFixed(2);
    },
    ASUsqft() {
      return (
        this.ASUItems.reduce((total, obj) => obj.total_cost + total, 0) /
        parseFloat(this.quote.sqft)
      );
    },
    totalCost(name) {
      let sum = 0;
      let el = this.constructtionSpecFiltered(name);
      if (el.length > 0) {
        el.map((e) => {
          if (this.quote.options.length > 0) {
            this.quote.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id) sum += o.total_cost;
            });
          }
          sum += e.total_cost;
        });
      }
      return sum;
    },
    totalCostSqft(name) {
      let sum = 0;
      let el = this.constructtionSpecFiltered(name);
      if (el.length > 0) {
        el.map((e) => {
          if (this.quote.options.length > 0) {
            this.quote.options.map((o) => {
              if (o.construction_work_id == e.construction_work_id)
                sum += o.cost_per_sqft;
            });
          }
          sum += e.cost_per_sqft;
        });
      }
      return sum;
    },
    //* SiteConditions methods
    SCtoggleAll(col) {
      this.SCactiveFilters[col] = this.ASUItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    SCtotalColumnShow(col) {
      let isSelected = false;
      if (this.SCdisplayColumnsCheck.length > 0) {
        let res = this.SCdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    SCcurrentItems: function (value) {
      this.SCfilteredItems = value;
    },
    SCsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.SCfilteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      // return 0;
    },
    SCclearAll(col) {
      this.SCactiveFilters[col] = [];
    },
    SCtoggleAll(col) {
      this.SCactiveFilters[col] = this.quote.site_conditions
        .map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    SCisFilterActive(name, value) {
      return this.SCactiveFilters[name] && this.SCactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    SCtoggleAllDrop(checked) {
      if (checked) {
        this.SCdisplayColumnsCheck = ["name"];
        if (this.SCfields.length > 0) {
          this.SCfields.map((f) => {
            this.SCdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.SCdisplayColumnsCheck = ["name"];
      }
    },
    // *CA methods
    //* CO methods
    CAtoggleAll(col) {
      this.CAactiveFilters[col] = this.CAItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    CAtotalColumnShow(col) {
      let isSelected = false;
      if (this.CAdisplayColumnsCheck.length > 0) {
        let res = this.CAdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    CAsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.CAfilteredItems.reduce((a, b) => a + (Number(b[key]) || 0), 0);
      // return 0;
    },
    // ASUclearAll(col) {
    //   this.activeFilters[col] = [];
    // },
    CAisFilterActive(name, value) {
      return this.CAactiveFilters[name] && this.CAactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    // ASUtoggleAll(col) {
    //   this.activeFilters[col] = this.quote.ffe_spec_quote
    //     .map((value) => value[col])
    //     .filter((value, index, _arr) => _arr.indexOf(value) == index)
    //     .sort();
    // },
    CAcurrentItems: function (value) {
      this.CAfilteredItems = value;
    },
    CAtoggleAllDrop(checked) {
      if (checked) {
        this.CAdisplayColumnsCheck = ["name"];
        if (this.CAfields.length > 0) {
          this.CAfields.map((f) => {
            this.CAdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.CAdisplayColumnsCheck = ["name"];
      }
    },
    // * reset cols
    resetCols(table) {
      if (table == "sc") {
        this.displayColumnsCheck = [
          "name",
          "unit_cost",
          // "vendor",
          "subcategory",
          "qty",
          "total_cost",
          "cost_per_sqft",
          "total_delivery_install",
        ];
      } else if (table == "asu") {
        this.ASUdisplayColumnsCheck = [
          "name",
          "internal_note",
          "uniformat",
          "total_cost",
          "cost_per_sqft",
        ];
      } else if (table == "cash") {
        this.CAdisplayColumnsCheck = [
          "name",
          "internal_note",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      } else {
        this.COdisplayColumnsCheck = [
          "name",
          "internal_note",
          "uniformat",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      }
    },
    //* ASU methods
    ASUtoggleAll(col) {
      this.ASUactiveFilters[col] = this.ASUItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    ASUtotalColumnShow(col) {
      let isSelected = false;
      if (this.ASUdisplayColumnsCheck.length > 0) {
        let res = this.ASUdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    ASUcurrentItems: function (value) {
      this.ASUfilteredItems = value;
    },
    ASUsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.ASUfilteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      // return 0;
    },
    ASUclearAll(col) {
      this.ASUactiveFilters[col] = [];
    },
    ASUtoggleAll(col) {
      this.ASUactiveFilters[col] = this.quote.options
        .map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    ASUisFilterActive(name, value) {
      return this.ASUactiveFilters[name] && this.ASUactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    ASUtoggleAllDrop(checked) {
      if (checked) {
        this.ASUdisplayColumnsCheck = ["name"];
        if (this.ASUfields.length > 0) {
          this.ASUfields.map((f) => {
            this.ASUdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.ASUdisplayColumnsCheck = ["name"];
      }
    },
    //* CO methods
    COtoggleAll(col) {
      this.COactiveFilters[col] = this.COItems.map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    COtotalColumnShow(col) {
      let isSelected = false;
      if (this.COdisplayColumnsCheck.length > 0) {
        let res = this.COdisplayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },
    COcurrentItems: function (value) {
      this.COfilteredItems = value;
    },
    COsumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      return this.COfilteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      // return 0;
    },
    COclearAll(col) {
      this.COactiveFilters[col] = [];
    },
    // COtoggleAll(col) {
    //   this.COactiveFilters[col] = this.quote.options
    //     .map((value) => value[col])
    //     .filter((value, index, _arr) => _arr.indexOf(value) == index)
    //     .sort();
    // },

    CAclearAll(col) {
      this.CAactiveFilters[col] = [];
    },
    COisFilterActive(name, value) {
      return this.COactiveFilters[name] && this.COactiveFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },
    COtoggleAllDrop(checked) {
      if (checked) {
        this.COdisplayColumnsCheck = ["name"];
        if (this.COfields.length > 0) {
          this.COfields.map((f) => {
            this.COdisplayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.COdisplayColumnsCheck = ["name"];
      }
    },
    getCodeName(name) {
      let tc = [];
      if (this.quote.base_spec_version < 3) {
        tc = tenderCodes.v1;
      } else {
        tc = tenderCodes.v2;
      }
      const res = tc.filter((t) => t.cost_code == name);
      if (res.length > 0) {
        return res[0].code_name;
      } else {
        return "";
      }
    },
    searchUniformatCodeName(code) {
      let codeName = "";
      this.uniformat["Construction"].map((u) => {
        if (u.code == code) {
          codeName = u.code_name;
        }
      });
      return codeName;
    },
    // tender export method
    exportTender() {
      const fileName = `${this.address} -  Tender Export CO.xlsx`;
      this.$http({
        url: `/opportunities/${this.$route.params.ActiveProjectId}/tender-export-co`,
        method: "GET",
        responseType: "arraybuffer",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    clicked(item) {
      // console.log(item);
      // const { sf_opportunity_id } = item;
      // this.$router.push({
      //   path: `/quote.ffe_spec_quote/${sf_opportunity_id}`,
      // });
    },
    // calculate base spec adjustment qty
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(
                construction_spec.total_cost +
                  this.constructionAdjustmentTotalCost(
                    construction_spec.construction_work_id
                  )
              ) *
                (this.quote.contingency_fee_percentage / 100)
            )
          : 0;
      if (
        this.excluded_codes_contingency_fee.filter(
          (e) => e == construction_spec.uniformat
        ).length > 0
      ) {
        result = 0;
      }
      // create custom adjustment for elements that qty is adjusted to 0
      // if (
      //   construction_spec.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result -=
      //     this.quote.base_spec_version > 3.6
      //       ? Number(
      //           Number(construction_spec.total_cost) *
      //             (this.quote.contingency_fee_percentage / 100)
      //         )
      //       : 0;
      // }
      // let cstrn_iten_filter = this.quote.construction_spec_quote.find(
      //   (c) => c.construction_work_id == construction_spec.construction_work_id
      // );
      // if (
      //   cstrn_iten_filter &&
      //   cstrn_iten_filter.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //     0
      // ) {
      //   result -=
      //     this.quote.base_spec_version > 3.6
      //       ? Number(
      //           Number(construction_spec.total_cost) *
      //             (this.quote.contingency_fee_percentage / 100)
      //         )
      //       : 0;
      // }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.label-exclusions {
  margin: auto;
}
.title_divider {
  border: none;
  border-left: 1px solid rgba(31, 41, 47, 0.05);
  height: auto;
  width: 1px;
  margin-right: 25px;
}
.increase {
  color: rgba(92, 177, 231, 1);
}

.decrease {
  color: #ea5455;
}

.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}

.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}

.cell-styling {
  height: 33px;
}

.cell-styling-double {
  height: 65px;
}
.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}
.muted-number {
  color: #b9b9c3;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.muted-base-spec {
  color: #b9b9c3;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

.card_title {
  color: #6e6b7b;
  margin-bottom: 4px;
  font-size: 14px;
}

.table-background-color {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
}
.table-background-spec {
  background-color: #babfc71f;
  color: #5e5873;
  text-transform: uppercase;
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
.v-data-table::v-deep th {
  color: rgba(50, 61, 76, 1) !important;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
}
</style>

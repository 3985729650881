<template>
  <div
    :class="[
      {
        expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-dark',
    ]"
    class="main-menu menu-fixed menu-accordion menu-shadow"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        :collapseTogglerIcon="collapseTogglerIcon"
        :toggleCollapsed="toggleCollapsed"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        name="header"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo">
                <b-img
                  v-if="isVerticalMenuCollapsed && isMouseHovered"
                  :src="appLogoImageDark"
                  alt="logo"
                  v-bind="{height:25}"
                />
                <b-img
                  v-else-if="!isVerticalMenuCollapsed"
                  :src="appLogoImageDark"
                  alt="logo"
                  v-bind="{height:25}"
                  class="ml-1"
                />
                <b-img
                  v-else-if="isVerticalMenuCollapsed"
                  :src="
                    appLogoImageCollapsedDark
                  "
                  alt="logo"
                  center
                  class="mt-1"
                  v-bind="{height:6}"
                  style="padding-left: 0.3rem"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                class="d-block d-xl-none"
                icon="XIcon"
                size="20"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                class="d-none d-xl-block collapse-toggle-icon"
                size="20"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <Transition name="fade" v-if="routeName.includes('Active Project')">
        <div
          class="my-2"
          style="width: 210px; margin-left: 30px; margin-right: 30px"
          v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)"
        >
          <h4><b>{{address_info}}</b></h4>
        </div>
      </Transition>
      <vertical-nav-menu-items
        :items="menuItems"
        class="navigation navigation-main"
      />
      <!-- <vertical-nav-menu-items
        v-if="!routeName.includes('Active Project')"
        :items="filterMenu"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-items
        v-else
        :items="activeFilterMenu"
        class="navigation navigation-main"
      /> -->
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BImg, BLink } from "bootstrap-vue";
import { computed, provide, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import navMenuItems from "@/navigation/vertical/default";
import activeNavMenuItems from "@/navigation/vertical/activeProjects";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
    address_info: {
      type: String,
      required: false,
      default:''
    },
  },
  data() {
    return {
      logoProps: {
        height: 30,
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    menuItems(){
      if(this.routeName.includes('Active Project')){
        return activeNavMenuItems
      } else {
        this.$root.$emit("update-signed-alert",true);
        this.$root.$emit("update-completed-alert",false);
        return navMenuItems
      }
    },
    filterMenu() {
      let filtered = navMenuItems.filter(
        (m) =>
          m.title == "Home" || m.title == "Opportunities" || m.title == "Active Projects"
      );
      return filtered;
    },
    activeFilterMenu() {
      let filtered = navMenuItems.filter(
        (m) =>
          m.title != "Home" && m.title != "Opportunities" && m.title != "Active Projects"
      );
      return filtered;
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const {
      appName,
      appLogoImage,
      appLogoImageCollapsed,
      appLogoImageDark,
      appLogoImageCollapsedDark,
    } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageCollapsed,
      appLogoImageDark,
      appLogoImageCollapsedDark,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

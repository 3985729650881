<template>
  <div>
    <div v-if="isBusy" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <div v-else style="padding-left: 20px">
      <b-row>
        <b-col cols="10">
          <h2
              class="notprintable my-1"
              style="font-weight: 700 !important; font-size: 28px!important; line-height: 34px;!important"
          >
            {{ opportunity.address }}
          </h2>
          <span>
            <uil-bill class="logo icon_possition" size="15px" />
            {{ opportunity.account_name }}
          </span>
          <span>
            <uil-tape class="logo icon_possition" size="15px" />
            {{
              opportunity.rentable_sqft.toLocaleString("en", { useGrouping: true }) +
              " sqft"
            }}
          </span></b-col
        >
        <b-col>
          <div
              class="mt-2 d-flex justify-content-end"
              v-if="opportunity.marked_as_complete == 0"
          >
            <!-- Dropdown -->
            <b-dropdown
                no-caret
                variant="outline-secondary"
                class="px-0"
                size="sm"
                dropdown
                offset="-135"
                v-if="revision.tfr_status != 'Archived' || isAdmin == 1"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item
                  v-if="
                  revision.tfr_status != 'Quote Completed' &&
                  revision.tfr_status != 'Archived'
                "
                  @click="$bvModal.show('editRevision')"
              >
                <feather-icon icon="Edit3Icon" style="height: 15px; width: 15px" />
                Edit Revision Name
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="
                  revision.tfr_status != null &&
                  revision.tfr_status != 'Quote Completed' &&
                  revision.tfr_status != 'Archived'
                "
                  @click="$bvModal.show('tfr-update-requirements')"
              >
                <feather-icon icon="Edit3Icon" style="height: 15px; width: 15px" />
                Edit Requirements
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="revision.tfr_status != 'Archived' && !revision.marked_as_active"
                  @click="archiveModal(revision.id)"
              >
                <uil-archive class="logo" size="15" /> Archive Revision
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="revision.tfr_status == 'Archived' && isAdmin == 1"
                  @click="unarchiveRevision(revision.id)"
                  :disabled="revision.tfr_status != 'Archived'"
              >
                <uil-archive class="logo" size="15" />
                <span class="align-middle ml-50">Unarchive Revision</span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="isAdmin == 1 && revision.quote_id == null"
                  @click="deleteRevision(revision.id)"
              >
                <uil-trash class="logo" size="15" />
                <span class="align-middle ml-50">Delete Revision</span>
              </b-dropdown-item>
              <b-dropdown-item
                  target="_blank"
                  v-if="
                  revision.tfr_status != 'Archived' &&
                  isAdmin == 1 &&
                  revision.quote_id != null
                "
                  @click="deleteQuote(revision.quote_id)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-card no-body class="mt-1">
        <b-row style="margin-right: 0px">
          <b-col cols="8">
            <div style="padding-left: 30px">
              <div>
                <p
                    style="
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 21px;
                    color: #636363;
                    padding-top: 40px;
                  "
                >
                  {{ revision.tfr }}
                  <br />
                  <b-badge
                      v-if="revision.marked_as_active"
                      pill
                      class="mt-1"
                      :class="badgeClass(true)"
                  >
                    Active
                  </b-badge>
                  <b-badge
                      v-else
                      pill
                      class="mt-1"
                      style="font-size: 12.5px"
                      :class="badgeClass(revision.tfr_status)"
                  >
                    {{ revision.tfr_status != null ? revision.tfr_status : "Draft" }}
                  </b-badge>
                </p>
                <p>
                  Spec set: {{ revision.spec_swap_set!=null?revision.spec_swap_set.name:'Clearspace Base Spec'}}
                </p>
                <!--                <p>-->
                <!--                  Addition set: {{ revision.addition_set!=null?revision.addition_set.name:'Clearspace Base Spec'}}-->
                <!--                </p>-->

              </div>
              <div style="padding-top: 30px">

                <div>
                  <div style="padding-left: 30px">
                    <div>
                      <p
                          style="
                          font-weight: 600;
                          font-size: 21px;
                          line-height: 21px;
                          color: #636363;
                          padding-top: 40px;
                        "
                      >
                        {{ revision.tfr }}
                      </p>
                      <div class="w-auto">
                        <!--                        add spec set name to be clear with the user-->
                        <p>
                          Spec set:  {{ revision.spec_swap_set_id?revision.spec_swap_set.name:'Clearspace Base Spec' }}
                        </p>
                      </div>
                    </div>
                    <div style="padding-top: 30px">
                      <p
                          style="
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 24px;
                          color: #636363;
                        "
                      >
                        Test Fit
                        <feather-icon
                            v-if="revision.test_fit_pdf === null && revision.test_fit_url!==null"
                            v-show="(revision.test_fit_url!==null || revision.test_fit_pdf !== null) && revision.tfr_status!==null && revision.tfr_status!=='Awaiting Test Fit'&& revision.tfr_status!=='Quote Completed'"
                            icon="Edit3Icon"
                            style="
                            cursor: pointer;
                            height: 15px;
                            width: 15px;
                            margin-right: 30px;
                          "
                            @click="$bvModal.show('editTestFit')"
                        />
                      </p>
                      <div class="d-flex">
                        <b-button
                            v-show="revision.tfr_status == null"
                            v-b-modal.requestTestFit
                            variant="outline-primary"
                        >
                          Submit Request
                        </b-button>
                        <b-button
                            v-show="revision.test_fit_url===null && revision.test_fit_pdf === null && revision.tfr_status !== null"
                            v-b-modal.attachTestFit
                            variant="outline-primary"
                        >
                          Attach Test Fit
                        </b-button>
                        <b-button
                            v-show="
                            revision.tfr_status != 'Awaiting Test Fit' &&
                              (revision.tfr_status !== null && revision.test_fit_url!==null)
                          "
                            variant="outline-secondary"
                            @click="displayTestFit"
                            role="presentation"
                            :disabled="revision.test_fit_pdf == null && revision.test_fit_url===null"
                        >
                          <feather-icon icon="ExternalLinkIcon" />
                          View Test Fit
                        </b-button>
                        <b-button
                            v-b-modal.reviewTestFit
                            variant="outline-primary"
                            style="margin-left: 10px"
                            v-show="revision.tfr_status === 'Awaiting Test Fit'"
                        >
                          {{revision.test_fit_pdf == null && revision.test_fit_url===null?'Skip':'Review'}}  Test Fit
                        </b-button>
                      </div>
                    </div>
                    <div style="padding-top: 30px">
                      <p
                          style="
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 24px;
                          color: #636363;
                          margin-bottom: 10px;
                        "
                      >
                        Quote
                      </p>
                      <b-row
                          v-if="revision.quote_id != null"
                          style="margin-bottom: 25px"
                      >
                        <b-col
                            md="6"
                            align-self="center"
                        >
                          <label
                              style="
                              font-weight: 600 !important;
                              font-size: 12px;
                              line-height: 23px;
                              color: #b9b9c3;
                              letter-spacing: 0.6px;
                              text-transform: uppercase;
                            "
                          >Project $/sqft</label>
                          <h2
                              style="
                              color: #6e6b7b;
                              font-weight: 500 !important;
                              font-size: 24px;
                              line-height: 24px;
                            "
                          >
                            ${{
                              Number(
                                  revision.quote_totals.project_dollar_per_sqft
                              ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}
                          </h2>
                        </b-col>
                        <b-col
                            md="6"
                            align-self="center"
                        >
                          <label
                              style="
                              font-weight: 600 !important;
                              font-size: 12px;
                              line-height: 23px;
                              color: #b9b9c3;
                              letter-spacing: 0.6px;
                              text-transform: uppercase;
                            "
                          >Project Total</label>
                          <h2
                              style="
                              color: #6e6b7b;
                              font-weight: 500 !important;
                              font-size: 24px;
                              line-height: 24px;
                            "
                          >
                            ${{
                              Number(
                                  revision.quote_totals.project_total
                              ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                            }}
                          </h2>
                        </b-col>
                      </b-row>
                      <b-button
                          v-show="revision.tfr_status == 'Awaiting Quote'"
                          v-b-modal.modal-duplication
                          variant="outline-primary"
                      >
                        Create a Quote
                      </b-button>
                      <b-button
                          v-show="revision.quote_id != null"
                          target="_blank"
                          variant="outline-primary"
                          :href="
                          '/opportunities/' +
                            opportunity.sf_opportunity_id +
                            '/' +
                            revision.quote_id
                        "
                      >
                        View Quote
                      </b-button>
                      <span
                          v-show="
                          revision.tfr_status != 'Awaiting Quote' &&
                            revision.quote_id == null
                        "
                          style="
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #b9b9c3;
                        "
                      >A quote can be made after a test fit is approved.</span>
                    </div>
                    <div style="padding-top: 40px; padding-bottom: 50px">
                      <p
                          style="
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 24px;
                          color: #636363;
                          margin-bottom: 10px;
                        "
                      >
                        Attachments
                      </p>
                      <b-button
                          variant="outline-secondary"
                          :href="
                          revision.attachments_folder_url == null
                            ? opportunity.attachments_folder_url
                            : revision.attachments_folder_url
                        "
                          target="_blank"
                          role="presentation"
                          :disabled="
                          revision.attachments_folder_url == null &&
                            opportunity.attachments_folder_url == null
                        "
                      >
                        <feather-icon icon="ExternalLinkIcon" />
                        View Attachments Folder
                      </b-button>
                    </div>
                    <hr>
                    <div style="padding-top: 40px">
                      <p
                          style="
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 24px;
                          color: #636363;
                          margin-bottom: 10px;
                        "
                      >
                        Sales Notes
                      </p>
                      <span
                          style="
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 21px;
                          color: #6e6b7b;
                          word-break: break-word;
                          white-space: pre-line;
                        "
                      >{{ revision.notes }}</span>
                    </div>
                    <div
                        v-show="
                        revision.tfr_status == 'Quote Completed' ||
                          revision.tfr_status == 'Reviewing Quote'
                      "
                        style="padding-top: 40px; padding-bottom: 40px"
                    >
                      <p
                          style="
                          font-weight: 600;
                          font-size: 15px;
                          line-height: 24px;
                          color: #636363;
                          margin-bottom: 10px;
                        "
                      >
                        Estimator Notes
                      </p>
                      <span
                          style="
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 21px;
                          color: #6e6b7b;
                          word-break: break-word;
                          white-space: pre-line;
                        "
                      >{{ revision.estimator_notes }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding-top: 30px">
                <p
                    style="
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #636363;
                    margin-bottom: 10px;
                  "
                >
                  Quote
                </p>
                <b-row style="margin-bottom: 25px" v-if="revision.quote_id != null">
                  <b-col md="6" align-self="center">
                    <label
                        style="
                        font-weight: 600 !important;
                        font-size: 12px;
                        line-height: 23px;
                        color: #b9b9c3;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                      "
                    >Project $/sqft</label
                    >
                    <h2
                        style="
                        color: #6e6b7b;
                        font-weight: 500 !important;
                        font-size: 24px;
                        line-height: 24px;
                      "
                    >
                      {{
                        Number(
                            revision.quote_totals.project_dollar_per_sqft
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h2>
                  </b-col>
                  <b-col md="6" align-self="center">
                    <label
                        style="
                        font-weight: 600 !important;
                        font-size: 12px;
                        line-height: 23px;
                        color: #b9b9c3;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                      "
                    >Project Total</label
                    >
                    <h2
                        style="
                        color: #6e6b7b;
                        font-weight: 500 !important;
                        font-size: 24px;
                        line-height: 24px;
                      "
                    >
                      {{
                        Number(revision.quote_totals.project_total).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                        )
                      }}
                    </h2>
                  </b-col>
                </b-row>
                <b-button
                    v-b-modal.modal-duplication
                    variant="outline-primary"
                    v-show="revision.tfr_status == 'Awaiting Quote'"
                >
                  Create a Quote
                </b-button>
                <b-button
                    target="_blank"
                    variant="outline-primary"
                    v-show="revision.quote_id != null"
                    :href="
                    '/opportunities/' +
                    opportunity.sf_opportunity_id +
                    '/' +
                    revision.quote_id
                  "
                >
                  View Quote
                </b-button>
                <!-- Dropdown -->

                <span
                    style="
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #b9b9c3;
                  "
                    v-show="
                    revision.tfr_status != 'Awaiting Quote' && revision.quote_id == null
                  "
                >A quote can be made after a test fit is approved.</span
                >
              </div>
              <div style="padding-top: 40px; padding-bottom: 50px">
                <p
                    style="
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #636363;
                    margin-bottom: 10px;
                  "
                >
                  Attachments
                </p>
                <b-button
                    variant="outline-secondary"
                    :href="
                    revision.attachments_folder_url == null
                      ? opportunity.attachments_folder_url
                      : revision.attachments_folder_url
                  "
                    target="_blank"
                    role="presentation"
                    :disabled="
                    revision.attachments_folder_url == null &&
                    opportunity.attachments_folder_url == null
                  "
                >
                  <feather-icon icon="ExternalLinkIcon" />
                  View Attachments Folder
                </b-button>
              </div>
              <hr />
              <div style="padding-top: 40px">
                <p
                    style="
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #636363;
                    margin-bottom: 10px;
                  "
                >
                  Sales Notes
                </p>
                <span
                    style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                    word-break: break-word;
                    white-space: pre-line;
                  "
                >{{ revision.notes }}</span
                >
              </div>
              <div
                  style="padding-top: 40px; padding-bottom: 40px"
                  v-show="
                  revision.tfr_status == 'Quote Completed' ||
                  revision.tfr_status == 'Reviewing Quote'
                "
              >
                <p
                    style="
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #636363;
                    margin-bottom: 10px;
                  "
                >
                  Estimator Notes
                </p>
                <span
                    style="
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                    word-break: break-word;
                    white-space: pre-line;
                  "
                >{{ revision.estimator_notes }}</span
                >
              </div>
            </div>
          </b-col>
          <b-col
              cols="4"
              style="
              border: 1px solid rgba(31, 41, 47, 0.05);
              padding-left: 0px;
              padding-right: 0px;
            "
          >
            <h5
                style="
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                color: #636363;
                padding-top: 20px;
                padding-left: 28px;
                padding-bottom: 15px;
                border: 1px solid rgba(31, 41, 47, 0.05);
                margin-bottom: 0px;
              "
            >
              Requirements
              <feather-icon
                  icon="Edit3Icon"
                  style="cursor: pointer; height: 15px; width: 15px; margin-right: 30px"
                  @click="$bvModal.show('tfr-update')"
                  v-show="revision.tfr_status == null"
              />
            </h5>
            <b-list-group style="position: relative; overflow-y: auto; height: 750px">
              <b-list-group-item
                  v-for="(requirement, index) in selectedRequirements"
                  :key="'req' + index"
                  tag="li"
                  style="border: 1px solid rgba(31, 41, 47, 0.05); padding-left: 28px"
              >
                <b-row>
                  <b-col
                      cols="2"
                      style="
                      padding-right: 13px;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 24px;
                      color: #6e6b7b;
                    "
                  >
                    {{ requirement.order }}
                  </b-col>
                  <b-col cols="9" style="padding-left: 0px">
                    <div
                        style="
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #636363;
                      "
                    >
                      {{ requirement.name }}
                    </div>
                    <div
                        style="
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #636363;
                        white-space: pre-line;
                      "
                    >
                      {{ requirement.description }}
                    </div>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal
        id="tfr-update"
        ref="tfr-update"
        scrollable
        size="lg"
        title="Edit Revision"
        :hide-footer="true"
        :no-close-on-backdrop="true"
    >
      <edit-tfr-modal
          :opp="opportunity"
          :revisionToEdit="revision"
          @updatedOptions="updatedTfr"
          @errorModal="errorModal"
      />
      <template #modal-footer="{ ok }">
        <b-button size="sm" style="display: none" variant="success" @click="ok()">
          ok
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="tfr-update-requirements"
        ref="tfr-update-requirements"
        scrollable
        size="lg"
        title="Edit Requirements"
        :hide-footer="true"
        :no-close-on-backdrop="true"
    >
      <requirements-tfr-modal
          :opp="opportunity"
          :revisionToEdit="revision"
          @updatedOptions="updatedTfr"
          @errorModal="errorModal"
      />
    </b-modal>
    <b-modal
        id="modal-duplication"
        scrollable
        size="lg"
        title="Create a Quote"
        :hide-footer="true"
        :no-close-on-backdrop="true"
    >
      <upload-modal-duplication :opp="opportunity" :revision="revision" />
      <template #modal-footer="{ ok }">
        <b-button size="sm" style="display: none" variant="success" @click="ok()">
          ok
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="requestTestFit"
        ref="requestTestFit"
        title="Request a Test Fit"
        @ok="handleRequestTestFit"
        ok-title="Continue"
        cancel-title="Go Back"
        cancel-variant="outline-secondary"
        :centered="true"
        body-class="modal-padding"
        :ok-disabled="modalIsBusy"
        :cancel-disabled="modalIsBusy"
        :no-close-on-backdrop="true"
    >
      <p class="m-1">
        Requesting a test fit will notify space planners and lock requirements for this
        revision.
      </p>
    </b-modal>

    <b-modal
        id="attachTestFit"
        ref="attachTestFit"
        title="Submit a Test Fit"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        ok-title="Submit"
        cancel-title="Cancel"
        cancel-variant="outline-secondary"
        :centered="true"
        body-class="modal-padding"
        :ok-disabled="modalIsBusy"
        :cancel-disabled="modalIsBusy"
        :no-close-on-backdrop="true"
    >
      <validation-observer ref="testFitLinkRef" tag="form">
        <form
            ref="attachTestFitForm"
            class="m-1"
            @submit.stop.prevent="validationTestFitLink"
        >
          <validation-provider
              #default="{ errors }"
              name="Test Fit Pdf File"
              rules="required:true"
          >
            <b-form-group label="Test Fit Pdf File" label-for="name-input">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="FileIcon" />
                </b-input-group-prepend>
                <b-form-file
                    v-model="pdf_file"
                    :state="Boolean(pdf_file)"
                    placeholder="Choose a Test Fit Pdf or drop it here..."
                    drop-placeholder="Test Fit Pdf file here..."
                    accept=".pdf"
                ></b-form-file>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal
        id="reviewTestFit"
        ref="reviewTestFit"
        title="Review a Test Fit"
        @ok="handleReviewTestFit"
        ok-title="Approve"
        cancel-title="Go Back"
        cancel-variant="outline-secondary"
        :centered="true"
        body-class="modal-padding"
        :ok-disabled="modalIsBusy"
        :cancel-disabled="modalIsBusy"
        :no-close-on-backdrop="true"
    >
      <p class="m-1">
        <b-alert v-if="revision.test_fit_url===null && revision.test_fit_pdf===null" show variant="danger" class="p-1">
          Test fit PDF not uploaded. You can proceed with creating the quote, but remember to upload and update the test fit later.
        </b-alert>
        Approving a test fit will send a request for a quote to be made. <br />You can
        create a new test fit request at any time.
      </p>
    </b-modal>
    <b-modal
        id="editTestFit"
        ref="editTestFit"
        title="Edit Test Fit"
        :centered="true"
        body-class="modal-padding"
        :no-close-on-backdrop="true"
    >
      <validation-observer
          ref="editTestFitLinkRef"
          tag="form"
      >
        <form ref="editTestFitLinkForm" class="m-1">
          <!--          <validation-provider-->
          <!--            #default="{ errors }"-->
          <!--            name="Test Fit Link"-->
          <!--            rules="required|url:{require_protocol:true}"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Test Fit Link"-->
          <!--              label-for="name-input"-->
          <!--            >-->
          <!--              <b-input-group>-->
          <!--                <b-input-group-prepend is-text>-->
          <!--                  <feather-icon icon="LinkIcon" />-->
          <!--                </b-input-group-prepend>-->
          <!--                <b-form-input-->
          <!--                  id="Edit Test Fit Link"-->
          <!--                  v-model="revision.test_fit_pdf"-->
          <!--                  required-->
          <!--                  placeholder="https://drive.google.com/folder..."-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </b-form-group>-->
          <!--          </validation-provider>-->
          <validation-provider
              #default="{ errors }"
              name="Test Fit Pdf File"
              rules="required:true"
          >
            <b-form-group label="Test Fit Pdf File" label-for="name-input">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="FileIcon" />
                </b-input-group-prepend>
                <b-form-file
                    v-model="pdf_file"
                    :state="Boolean(pdf_file)"
                    placeholder="Choose a Test Fit Pdf or drop it here..."
                    drop-placeholder="Test Fit Pdf file here..."
                    accept=".pdf"
                ></b-form-file>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button
            variant="outline-secondary"
            :disabled="modalIsBusy"
            @click="cancel()"
        >
          Cancel
        </b-button>
<!--        <b-button-->
<!--            variant="outline-secondary"-->
<!--            :disabled="modalIsBusy"-->
<!--            @click="removeTestFitLink()"-->
<!--        >-->
<!--          Remove Link-->
<!--        </b-button>-->
        <!-- Button with custom close trigger value -->
        <b-button
            variant="primary"
            :disabled="modalIsBusy"
            @click="validationEditTestFitLink()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="editRevision"
        ref="editRevision"
        title="Edit Revision Name"
        :centered="true"
        body-class="modal-padding"
        :no-close-on-backdrop="true"
    >
      <validation-observer ref="editRevisionRef" tag="form">
        <form ref="editRevisionForm">
          <validation-provider #default="{ errors }" name="Test Fit " rules="required">
            <b-form-group class="mx-1">
              <label for="InputHelp">Revision Name</label>
              <b-input-group>
                <b-input-group-prepend is-text>
                  {{ opportunity.sf_opportunity_number + "-R" }}
                </b-input-group-prepend>
                <b-form-input placeholder="01H" v-model="revision.revision" />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </form>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel()" :disabled="modalIsBusy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="updateRevisionName()" :disabled="modalIsBusy">
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="archiveQuote"
        ref="archiveQuote"
        title="Archive Revision"
        @ok="archiveRevision"
        ok-title="Archive"
        cancel-title="Go Back"
        cancel-variant="outline-secondary"
        :centered="true"
        body-class="modal-padding"
        :no-close-on-backdrop="true"
    >
      <div class="m-1">
        An archived quote will still be available to view but can no longer be edited.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBModal,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup,
  BFormFile, BAlert,
} from 'bootstrap-vue'
import UploadModalDuplication from "@/views/Quotes/UploadModalDuplication.vue";
import CreateTfrModal from "@/views/Tfrs/Components/CreateTfrModal.vue";
import EditTfrModal from "@/views/Tfrs/Components/EditTfrModal.vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { UilBill, UilSuitcase, UilTape, UilArchive, UilTrash } from "@iconscout/vue-unicons";
import moment from "moment";
import RequirementsTfrModal from "@/views/Tfrs/Components/RequirementsTfrModal.vue";

export default {
  components: {
    BAlert,
    BCard,
    BTable,
    BCol,
    BRow,
    BSpinner,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BCardText,
    BListGroupItem,
    BCollapse,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BInputGroupPrepend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    UilBill,
    UilSuitcase,
    UilTape,
    UploadModalDuplication,
    EditTfrModal,
    CreateTfrModal,
    ToastificationContent,
    RequirementsTfrModal,
    UilArchive,
    UilTrash,
    BFormFile,
  },
  props: {},
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      opportunityId: this.$route.params.ActiveProjectId,
      userName: localStorage.getItem("name"),
      isAdmin: localStorage.getItem("isAdmin"),
      isBusy: true,
      tableIsBusy: true,
      testFitLink: "",
      testFitState: null,
      modalIsBusy: false,
      revision: [],
      selectedRequirements: [],
      selectedIndex: [],
      attachments_folder_url: null,
      opportunity: {
        rentable_sqft: 0,
      },
      idToArchive: 0,
      pdf_file: null,
    };
  },
  watch: {
    selectedIndexProp(newValue, oldValue) {
      this.selectedIndex = newValue;
    },
    revisionProp(newValue, oldValue) {
      this.revision = newValue;
      this.selectedRequirements = this.revision.requirements;
    },
  },
  beforeUpdate() {},
  mounted() {
    this.getOpportunity();
  },
  methods: {
    getOpportunity() {
      this.$http
          .get(`/opportunities/${this.opportunityId}`)
          .then((response) => {
            this.opportunity = response.data;
            // this.quotes = this.opportunity.quotes;
            this.revision = this.opportunity.tfrs.filter(
                (t) => t.id == this.$route.params.tfr_id
            );
            this.revision = this.revision[0];
            this.attachments_folder_url = this.opportunity.attachments_folder_url;
            this.selectedIndex = 0;
            this.selectedRequirements = this.revision.requirements;
            // console.log(this.quotes);
            this.isBusy = false;
            this.tableIsBusy = false;
            this.updateBreadcrumb();
            if (this.routeRevisionId) {
              const index = this.opportunity.tfrs
                  .map((e) => e.tfr)
                  .indexOf(this.routeRevisionId);
              this.revision = this.opportunity.tfrs[index];
              this.selectedRequirements = this.revision.requirements;
              this.selectedIndex = index;
            }
          })
          .catch((error) => {
            this.isBusy = false;
            this.tableIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },
    updateBreadcrumb() {
      if (this.opportunity.submitted_for_handover) {
        this.$route.meta.breadcrumb[0].text = `Active Project`;
      } else {
        this.$route.meta.breadcrumb[0].text = `Opportunities`;
      }
      this.$route.meta.breadcrumb[1].text = `${this.opportunity.address}`;
      this.$route.meta.breadcrumb[1].to = `/active/${this.$route.params.ActiveProjectId}`;
      this.$route.meta.breadcrumb[1].active = true;
      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[2].text = `Revisions`;
      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[3].text = this.revision.tfr;
      this.$route.meta.breadcrumb[3].active = false;
      this.$root.$emit("rerender-breadcrumb");
      if (this.opportunity.tfr_name) {
        document.title = `[${this.revision.tfr}] View Quote`;
      }
    },
    clicked(item) {
      const { id } = item;
      this.$router.push(`/opportunities/${this.opportunityId}/${id}`);
      //window.open(`/opportunities/${this.opportunityId}/${id}`, '_blank');
    },
    deleteQuote(id) {
      this.tableIsBusy = true;
      this.$http
          .delete(`/quote/${id}`)
          .then((response) => {
            this.showToast(
                "success",
                "Quote has been successfully deleted.",
                "Quote Deleted Successfully"
            );
            this.getOpportunity();
            this.tableIsBusy = false;
          })
          .catch((error) => {
            this.showToast(
                "danger",
                "Error Deleting Quote",
                "Unable to delete quote at this time."
            );
            this.tableIsBusy = false;
          });
    },
    initials(text) {
      const initial = text
          .match(/(\b\S)?/g)
          .join("")
          .match(/(^\S|\S$)?/g)
          .join("")
          .toUpperCase();

      return initial;
    },
    getQuoteLastUpdated(item) {
      if (item.updated_by == null) {
        if (item.created_by == null) {
          return "Sales Force";
        }
        return item.created_by;
      }
      return item.updated_by;
    },
    transformToJson(requirements) {
      return JSON.stringify(requirements);
    },
    selectedRevision(revision, index) {
      this.revision = revision;
      this.selectedRequirements = revision.requirements;
      this.selectedIndex = index;
    },
    onChange(event) {
      this.reorder();
    },
    reorder() {
      this.revision.requirements.forEach((item, index) => (item.order = index + 1));
    },
    aDayAgo(date) {
      var a = moment();
      var b = moment(date);
      return a.diff(b, "days");
    },
    formattedDate(date) {
      return moment(date).format("MMM Do YYYY");
    },
    submitRequestModal(bvModalEvent) {
      this.$bvModal
          .msgBoxConfirm(
              "Requesting a test fit will notify space planners and lock requirements for this revision.",
              {
                title: "Request a Test Fit",
                size: "sm",
                okVariant: "primary",
                okTitle: "Continue",
                cancelTitle: "Go Back",
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
                bodyClass: "modal-padding",
              }
          )
          .then((value) => {});
    },
    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Draft") {
        return "draft";
      } else if (status == true) {
        return "submitted-for-handover";
      }
    },
    checkFormValidity() {
      const valid = this.$refs.attachTestFitForm.checkValidity();
      this.testFitState = valid;
      return valid;
    },
    resetModal() {
      this.testFitLink = "";
      this.testFitState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleRequestTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmitRequestTestFit();
    },
    handleReviewTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleReviewTestFit();
    },
    handleReviseTestFit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleReviseTestFit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      if (!this.pdf_file) {
        this.showToast(
            "danger",
            "Test fit pdf file is required",
            "Test Fit Error"
        );
        return
      }
      // Push the name to submitted names
      this.modalIsBusy = true;
      // if (!this.isHttpValid(this.testFitLink)) {
      //   this.showToast(
      //       "danger",
      //       "Test fit link must contain https:// or http:// ",
      //       "Test Fit URl not valid"
      //   );
      //   this.modalIsBusy = false;
      // } else {
      const testFitLinkData = {
        testFitLink: null,
      };
      let formData = new FormData();
      formData.append('pdf_file', this.pdf_file)
      this.$http
          .post(
              `/convert-and-upload`,
              formData
          )
          .then((response) => {
            testFitLinkData.test_fit_orientation=response.data.orientation;
            testFitLinkData.test_fit_url=response.data.public_url;
            testFitLinkData.test_fit_height=response.data.height;
            testFitLinkData.test_fit_width=response.data.width;
            this.$http
                .post(
                    `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,
                    testFitLinkData
                )
                .then((response) => {
                  this.modalIsBusy = false;
                  this.revision = response.data.data;
                  this.opportunity.tfrs[this.selectedIndex] = response.data.data;
                  this.showToast(
                      "success",
                      "Sales will be notified to review.",
                      "Test Fit Submitted"
                  );
                  this.pdf_file = null
                  // Hide the modal manually
                  this.$nextTick(() => {
                    //this.$bvModal.hide('attachTestFit')
                    this.$refs["attachTestFit"].hide();
                  });
                })
                .catch((error) => {
                  this.modalIsBusy = false;
                  this.showToast("danger", error.response.data.message);
                });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
      // }
    },
    handleSubmitRequestTestFit() {
      this.modalIsBusy = true;
      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/request-test-fit`
          )
          .then((response) => {
            this.modalIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast(
                "success",
                "A test fit will be requested from space planners.",
                "Test Fit Requested"
            );
            this.$nextTick(() => {
              this.$refs["requestTestFit"].hide();
              //this.$bvModal.toggle('requestTestFit')
            });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },
    handleReviewTestFit() {
      this.modalIsBusy = true;
      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/review-test-fit`
          )
          .then((response) => {
            this.modalIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast("success", "A quote will be requested.", "Test Fit Approved");
            this.$nextTick(() => {
              this.$refs["reviewTestFit"].hide();
              //this.$bvModal.toggle('reviewTestFit')
            });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },
    handleReviseTestFit() {
      this.modalIsBusy = true;
      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/revise-test-fit`
          )
          .then((response) => {
            this.modalIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast(
                "success",
                "You can create a new test fit request at any time.",
                "Test Fit Archived"
            );
            this.$nextTick(() => {
              this.$refs["reviewTestFit"].hide();
              //this.$bvModal.toggle('reviewTestFit')
            });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },

    removeTestFitLink() {
      this.modalIsBusy = true;
      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/remove-test-fit`
          )
          .then((response) => {
            this.modalIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast(
                "success",
                "You can add the Test Fit Link again at any time.",
                "Test Fit Removed"
            );
            this.$nextTick(() => {
              this.$refs["editTestFit"].hide();
              //this.$bvModal.toggle('reviewTestFit')
            });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },

    updateTestFitLink() {
      this.modalIsBusy = true;
      const testFitLinkData = {
        testFitLink: this.revision.test_fit_pdf,
      };

      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,
              testFitLinkData
          )
          .then((response) => {
            this.modalIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast(
                "success",
                "Sales will be notified to review.",
                "Test Fit Submitted"
            );
            // Hide the modal manually
            this.$nextTick(() => {
              //this.$bvModal.hide('attachTestFit')
              this.$refs["editTestFit"].hide();
            });
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast("danger", error.response.data.message);
          });
    },

    validationTestFitLink() {
      return new Promise((resolve, reject) => {
        this.$refs.testFitLinkRef.validate().then((success) => {
          if (success) {
            this.modalIsBusy = true;
            this.$http
                .post(
                    `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,
                    testFitLinkData
                )
                .then((response) => {
                  this.modalIsBusy = false;
                  this.revision = response.data.data;
                  this.opportunity.tfrs[this.selectedIndex] = response.data.data;
                  this.showToast(
                      "success",
                      "Sales will be notified to review.",
                      "Test Fit Submitted"
                  );
                  // Hide the modal manually
                  this.$nextTick(() => {
                    this.$refs["attachTestFit"].hide();
                    //this.$bvModal.toggle('attachTestFit')
                  });
                  resolve(true);
                })
                .catch((error) => {
                  this.modalIsBusy = false;
                  this.showToast("danger", error.response.data.message);
                });
          } else {
            reject();
          }
        });
      });
    },
    validationEditTestFitLink() {
      return new Promise((resolve, reject) => {
        this.$refs.editTestFitLinkRef.validate().then(success => {
          if(!this.pdf_file){
            this.showToast(
                "danger",
                "Test fit pdf file is required",
                "Test Fit Error"
            );
            return
          }
          if (success) {
            this.modalIsBusy = true
            const testFitLinkData = {
              testFitLink: null,
            }

            let formData = new FormData();
            formData.append('pdf_file', this.pdf_file)
            this.$http
                .post(
                    `/convert-and-upload`,
                    formData
                )
                .then((response) => {
                  testFitLinkData.test_fit_orientation=response.data.orientation;
                  testFitLinkData.test_fit_url=response.data.public_url;
                  testFitLinkData.test_fit_height=response.data.height;
                  testFitLinkData.test_fit_width=response.data.width;
                  this.$http
                      .post(
                          `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/attach-test-fit`,
                          testFitLinkData
                      )
                      .then((response) => {
                        this.modalIsBusy = false;
                        this.revision = response.data.data;
                        this.opportunity.tfrs[this.selectedIndex] = response.data.data;
                        this.showToast(
                            "success",
                            "Sales will be notified to review.",
                            "Test Fit Submitted"
                        );
                        // Hide the modal manually
                        this.$nextTick(() => {
                          //this.$bvModal.hide('attachTestFit')
                          this.$refs["editTestFit"].hide();
                          this.pdf_file = null;
                        });
                      })
                      .catch((error) => {
                        this.modalIsBusy = false;
                        this.showToast("danger", error.response.data.message);
                      });
                })
                .catch((error) => {
                  this.modalIsBusy = false;
                  this.showToast("danger", error.response.data.message);
                });
          } else {
            reject()
          }
        })
      })
    },
    updateRevisionName() {
      return new Promise((resolve, reject) => {
        this.$refs.editRevisionRef.validate().then((success) => {
          if (success) {
            this.modalIsBusy = true;
            const revisionData = {
              revision: this.revision.revision,
            };

            this.$http
                .post(
                    `/opportunities/${this.opportunityId}/tfr/${this.revision.id}/update-revision-name`,
                    revisionData
                )
                .then((response) => {
                  this.modalIsBusy = false;
                  this.revision = response.data.data;
                  this.opportunity.tfrs[this.selectedIndex] = response.data.data;
                  this.updateBreadcrumb();
                  this.showToast("success", "", "Revision Name Updated");
                  // Hide the modal manually
                  this.$nextTick(() => {
                    //this.$bvModal.hide('attachTestFit')
                    this.$refs["editRevision"].hide();
                  });
                  resolve(true);
                })
                .catch((error) => {
                  this.modalIsBusy = false;
                  this.showToast("danger", error.response.data.message);
                });
          } else {
            reject();
          }
        });
      });
    },
    updatedTfr(variable) {
      this.revision = variable;
      this.opportunity.tfrs[this.selectedIndex] = variable;
      this.selectedRequirements = variable.requirements;
      this.showToast("success", "Revision Updated Successfully", "Revision Updated");
      this.$refs["tfr-update"].hide();
      this.$refs["tfr-update-requirements"].hide();
    },
    createdTfr(variable) {
      this.showToast("success", "Revision Created Successfully", "Revision Created");
      this.$refs["tfr-creation"].hide();
      this.revision = variable.tfr;
      this.opportunity = variable.opportunity;
      var tfrId = this.revision.id;
      var index = this.opportunity.tfrs.indexOf(
          this.opportunity.tfrs.filter(function (item) {
            return item.ID == tfrId;
          })[0]
      );
      this.selectedRequirements = this.revision.requirements;
      this.selectedIndex = index;
    },
    errorModal(variable) {
      this.showToast("danger", "Contact Digital for support.", "Something went wrong");
    },
    unarchiveRevision(id) {
      this.tableIsBusy = true;
      this.$http
          .get(`/opportunities/${this.opportunityId}/tfr/${id}/unarchive`)
          .then((response) => {
            this.tableIsBusy = false;
            this.revision = response.data.data;
            this.opportunity.tfrs[this.selectedIndex] = response.data.data;
            this.showToast("success", "Revision unarchived and reverted", "Unarchived");
          })
          .catch((error) => {
            this.modalIsBusy = false;
            this.showToast(
                "danger",
                "Contact Digital for support.",
                "Something went wrong"
            );
          });
    },
    archiveRevision() {
      this.$http
          .post(
              `/opportunities/${this.opportunityId}/tfr/${this.idToArchive}/revise-test-fit`
          )
          .then((response) => {
            console.log(response);

            this.revision = response.data.data;
            // this.quote.tfr.tfr_status = "Archived";
            this.showToast(
                "success",
                "The quote will still be available to view but can no longer be edited.",
                "Quote Archived"
            );
          })
          .catch((error) => {
            console.log(error);
            this.showToast(
                "danger",
                "Contact Digital for support.",
                "Something went wrong"
            );
          });
    },
    deleteRevision(id){
      console.log('revision to delete id:' + id)
      this.$emit('tfrReload')
      this.$http
          .delete(
              `/opportunities/${this.opportunityId}/tfr/${id}`,
          )
          .then(response => {
            if(response.status === 200){
              this.showToast(
                  'success',
                  'The revision was removed successfully .',
                  'Revision Deleted',
              )
              this.$router.push(`/active/${this.opportunityId}`)
            }else{
              console.log(response)
              this.showToast(
                  'danger',
                  'Contact Digital for support.',
                  'Something went wrong',
              )
            }
          })
          .catch(error => {
            this.showToast(
                'danger',
                'Contact Digital for support.',
                'Something went wrong',
            )
          })
    },
    displayTestFit(){
      //revision.test_fit_url?revision.test_fit_url:revision.test_fit_pdf
      let title = this.revision.tfr +' - PDF';
      if(this.revision.test_fit_url){
        var newWin = window.open('/opportunities/'+this.opportunityId+'/tfr-pdf/'+this.revision.id+'?url='+this.revision.test_fit_url, '_blank');
        // add a load listener to the window so that the title gets changed on page load
        newWin.addEventListener("load", function() {
          newWin.document.title = title;
        });
      }else{
        window.open(this.revision.test_fit_pdf, '_blank')
      }
    },
    archiveModal(id) {
      this.idToArchive = id;
      this.$bvModal.show("archiveQuote");
    },
    isHttpValid(str) {
      try {
        const newUrl = new URL(str);
        return newUrl.protocol === "http:" || newUrl.protocol === "https:";
      } catch (err) {
        return false;
      }
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <b-row>
      <!-- start header info -->
      <b-col cols="8" class="mb-0 pb-0">
        <h1>
          <b> Revisions </b>
        </h1>
      </b-col>
      <b-col cols="4" class="mb-0 pb-0" align-self="end">
        <div style="margin-right: 10px" class="d-flex justify-content-end">
          <b-button
            v-if="!quote.ccdc_signed"
            class="py-0 custom_btn"
            style="height: 38px; font-size: 14px"
            variant="primary"
            @click="$emit('marksigned')"
          >
            Mark Contract Signed
          </b-button>
          <div v-if="quote.ccdc_signed && isAdmin == 1">
            <b-button
              variant="outline-secondary"
              style="margin-right: 10px; font-size: 14px"
              @click="$emit('revertMarksigned')"
            >
              Revert Mark Contract as Signed (Admins Only)
            </b-button>
          </div>
          <b-dropdown
            right
            no-caret
            toggle-class="rounded-square p-0 "
            style="width: 38px; height: 38px; margin-left: 5px"
            variant="outline-secondary"
          >
            <template #button-content>
              <feather-icon class="btn-icon" icon="MoreVerticalIcon" size="15" />
            </template>
            <b-dropdown-item
              :href="`/opportunities/${this.$route.params.ActiveProjectId}/compare-quotes/`"
              style="font-size: 14px"
            >
              <uil-chart-line class="logo" size="15" />
              Compare Quotes
            </b-dropdown-item>
            <b-dropdown-divider v-if="quote.marked_as_complete!=1"></b-dropdown-divider>
            <b-dropdown-item
              @click="markAsCompleted"
              style="font-size: 14px"
              v-if="quote.ccdc_signed &&quote.marked_as_complete==0"
            >
              <uil-check-circle class="logo" size="15" />
              Mark Project as Complete
            </b-dropdown-item>
            <b-dropdown-item @click="markAsLost" style="font-size: 14px" 
              v-if="quote.marked_as_complete==0">
              <uil-times-circle class="logo" size="15" />
              Mark Project as Lost
            </b-dropdown-item>
            <b-dropdown-divider v-if="isAdmin == 1"></b-dropdown-divider>
            <b-dropdown-item
              @click="revertMarkedAsComplete"
              v-if="quote.marked_as_complete && isAdmin == 1"
              style="font-size: 14px"
            >
              <uil-sync class="logo" size="15" />
              Revert Marked as Complete
            </b-dropdown-item>
            <b-dropdown-item
              @click="revertSubmittedHandover"
              v-if="quote.submitted_for_handover && isAdmin == 1&&quote.marked_as_complete==0 "
              style="font-size: 14px"
            >
              <uil-sync class="logo" size="15" />
              Revert Submitted for Handover
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col cols="9" class="mt-0 pt-1">
        <div>
          <b-row>
            <b-col cols="12" class="pb-0">
              <b-card>
                <b-row>
                  <b-col cols="8" class="pb-0">
                    <h5>
                      <strong>{{
                        quote.ccdc_signed ? "Contract" : "Active Revision"
                      }}</strong>
                    </h5>
                  </b-col>
                  <b-col cols="4" class="text-right pb-0">
                    <b-button
                      @click="toRevisionPage(quote.active_tfr)"
                      variant="outline-primary"
                      class="custom_btn"
                      style="font-size: 14px"
                      >View</b-button
                    >
                    <b-dropdown
                      v-if="!quote.ccdc_signed"
                      no-caret
                      toggle-class="rounded-square p-0 "
                      style="width: 38px; height: 38px; margin-left: 10px"
                      variant="outline-secondary"
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          class="btn-icon"
                          icon="MoreVerticalIcon"
                          size="15"
                        />
                      </template>
                      <b-dropdown-item
                        @click="update_tfr_modal = true"
                        style="font-size: 14px"
                      >
                        <uil-refresh class="logo" size="15" />
                        Replace Active Revision
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col cols="12" class="py-0">
                    <h4 style="text-transform: uppercase">
                      <strong>{{ quote.active_tfr.tfr }}</strong>
                    </h4>
                  </b-col>
                  <b-col cols="4">
                    <label for="qtotal" class="label_tfr">quote total</label>
                    <h4 style="text-transform: uppercase">
                      <strong>
                        {{
                          Number(
                            quote.active_tfr.quote_totals.project_total
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}</strong
                      >
                    </h4>
                  </b-col>
                  <b-col cols="4">
                    <label for="sqft" class="label_tfr">$ per SQFT</label>
                    <h4 style="text-transform: uppercase">
                      <strong>
                        {{
                          Number(
                            quote.active_tfr.quote_totals.project_dollar_per_sqft
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}</strong
                      >
                    </h4>
                  </b-col>
                  <b-col cols="4">
                    <label for="updated" class="label_tfr">Last updated</label>
                    <div v-if="!quote.ccdc_signed">
                      <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                        <b-avatar
                          v-if="
                            initials(quote.active_tfr.updated_by) == 'PL' ||
                            initials(quote.active_tfr.updated_by) == 'AL' ||
                            initials(quote.active_tfr.updated_by) == 'SR' ||
                            initials(quote.active_tfr.updated_by) == 'TR'
                          "
                          size="30"
                          variant="light-primary"
                          badge
                          :src="require('@/assets/images/avatars/gandalf.jpeg')"
                          class="badge-minimal"
                          badge-variant="success"
                        />
                        <b-avatar
                          v-else-if="initials(quote.active_tfr.updated_by) == 'EG'"
                          size="30"
                          variant="light-primary"
                          badge
                          :src="require('@/assets/images/avatars/grumpy.jpg')"
                          class="badge-minimal"
                          badge-variant="success"
                        />
                        <b-avatar
                          v-else
                          size="30"
                          :variant="
                            quote.active_tfr.updated_by == userName
                              ? 'light-primary'
                              : 'light-secondary'
                          "
                          :text="initials(quote.active_tfr.updated_by)"
                          class="badge-minimal"
                        />
                      </span>
                      Updated at {{ formatDatetime(quote.active_tfr.updated_at) }}
                    </div>
                    <div v-else>
                      <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                        <b-avatar
                          v-if="
                            initials(quote.active_tfr.updated_by) == 'PL' ||
                            initials(quote.active_tfr.updated_by) == 'AL' ||
                            initials(quote.active_tfr.updated_by) == 'SR' ||
                            initials(quote.active_tfr.updated_by) == 'TR'
                          "
                          size="30"
                          variant="light-primary"
                          badge
                          :src="require('@/assets/images/avatars/gandalf.jpeg')"
                          class="badge-minimal"
                          badge-variant="success"
                        />
                        <b-avatar
                          v-else-if="initials(quote.active_tfr.updated_by) == 'EG'"
                          size="30"
                          variant="light-primary"
                          badge
                          :src="require('@/assets/images/avatars/grumpy.jpg')"
                          class="badge-minimal"
                          badge-variant="success"
                        />
                        <b-avatar
                          v-else
                          size="30"
                          :variant="
                            quote.active_tfr.updated_by == userName
                              ? 'light-primary'
                              : 'light-secondary'
                          "
                          :text="initials(quote.active_tfr.updated_by)"
                          class="badge-minimal"
                        />
                      </span>
                      {{ quote.active_tfr.updated_by }} updated
                      {{ formatDatetime(quote.active_tfr.updated_at) }}
                    </div>
                    <!-- toltip here -->
                    <b-tooltip target="disabled-wrapper">{{
                      quote.active_tfr.updated_by
                    }}</b-tooltip>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12" class="pt-0">
              <b-card v-if="!quote.ccdc_signed">
                <b-row>
                  <b-col cols="8">
                    <h5><strong>Revisions</strong></h5>
                  </b-col>
                  <b-col cols="4" class="text-right">
                    <b-button
                      @click="createTfr"
                      variant="outline-primary"
                      class="custom_btn"
                      style="font-size: 14px"
                      >Create Revision</b-button
                    >
                  </b-col>
                </b-row>
                <div class="mt-2">
                  <v-data-table
                    v-if="tfrs.length > 0"
                    :items="tfrs"
                    :headers="fields"
                    @click:row="toRevisionPage"
                  >
                    <template v-slot:item.quote_totals.project_total="{ item }">
                      {{
                        item.quote_totals.project_total
                          ? Number(item.quote_totals.project_total).toLocaleString(
                              "en-CA",
                              {
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: "CAD",
                              }
                            )
                          : "0.00"
                      }}
                    </template>
                    <template v-slot:item.tfr="{ item }">
                      <strong style="color: #6e6b7b">{{ item.tfr }}</strong>
                    </template>
                    <template v-slot:item.quote_totals.project_dollar_per_sqft="{ item }">
                      {{
                        item.quote_totals.project_dollar_per_sqft
                          ? Number(
                              item.quote_totals.project_dollar_per_sqft
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : "0.00"
                      }}
                    </template>
                    <template v-slot:item.tfr_status="{ item }">
                      <b-badge
                        pill
                        variant="primary"
                        :class="
                          item.tfr_status != null ? badgeClass(item.tfr_status) : 'draft'
                        "
                      >
                        {{ item.tfr_status != null ? item.tfr_status : "Draft" }}
                      </b-badge>
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      <p>
                        <span
                          :id="'disabled-wrapper' + item.id"
                          class="d-inline-block"
                          style="margin-top: 10px; margin-bottom: -10px"
                          tabindex="0"
                        >
                          <b-avatar
                            v-if="
                              initials(item.updated_by) == 'PL' ||
                              initials(item.updated_by) == 'AL' ||
                              initials(item.updated_by) == 'SR' ||
                              initials(item.updated_by) == 'TR'
                            "
                            size="30"
                            variant="light-primary"
                            badge
                            :src="require('@/assets/images/avatars/gandalf.jpeg')"
                            class="badge-minimal"
                            badge-variant="success"
                          />
                          <b-avatar
                            v-else-if="initials(item.updated_by) == 'EG'"
                            size="30"
                            variant="light-primary"
                            badge
                            :src="require('@/assets/images/avatars/grumpy.jpg')"
                            class="badge-minimal"
                            badge-variant="success"
                          />
                          <b-avatar
                            v-else
                            size="30"
                            :variant="
                              item.updated_by == userName
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                            :text="
                              initials(
                                item.updated_by != null
                                  ? item.updated_by
                                  : item.created_by
                              )
                            "
                            class="badge-minimal"
                          />
                        </span>
                        {{ item.updated_by != null ? "Updated" : "Created" }}
                        {{ formatDatetime(item.updated_at) }}
                      </p>
                      <!-- toltip here -->
                      <b-tooltip :target="'disabled-wrapper' + item.id">{{
                        item.updated_by != null ? item.updated_by : item.created_by
                      }}</b-tooltip>
                    </template>
                  </v-data-table>
                  <div v-else class="text-center">
                    <h3 class="text-muted" style="font-weight: 600">
                      No Revisions to Show
                    </h3>
                    <p class="text-muted">
                      Create a Revision order to handle changes in project scope
                      <br />before a contract has been signed.
                    </p>
                  </div>
                </div>
              </b-card>
              <b-card v-else>
                <b-row>
                  <b-col cols="8">
                    <h5><strong>Change Orders</strong></h5>
                  </b-col>
                  <b-col cols="4" class="text-right">
                    <b-button
                      @click="$emit('openCOCreate')"
                      variant="outline-primary"
                      style="font-size: 14px"
                      class="custom_btn"
                      v-if="!quote.marked_as_complete"
                      >Create Change Order</b-button
                    >
                  </b-col>
                </b-row>
                <div class="mt-2">
                  <v-data-table
                    v-if="CO.length > 0"
                    :items="CO"
                    :headers="fieldsCO"
                    @click:row="toCOPage"
                  >
                    <template v-slot:item.status="{ item }">
                      <b-badge
                        pill
                        variant="primary"
                        :class="item.status != null ? CObadgeClass(item.status) : ''"
                      >
                        {{ item.status != null ? item.status : "- -" }}
                      </b-badge>
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      <p>
                        <span
                          :id="'disabled-wrapper' + item.id"
                          class="d-inline-block"
                          style="margin-top: 10px; margin-bottom: -10px"
                          tabindex="0"
                        >
                          <b-avatar
                            v-if="
                              initials(item.updated_by) == 'PL' ||
                              initials(item.updated_by) == 'AL' ||
                              initials(item.updated_by) == 'SR' ||
                              initials(item.updated_by) == 'TR'
                            "
                            size="30"
                            variant="light-primary"
                            badge
                            :src="require('@/assets/images/avatars/gandalf.jpeg')"
                            class="badge-minimal"
                            badge-variant="success"
                          />
                          <b-avatar
                            v-else-if="initials(item.updated_by) == 'EG'"
                            size="30"
                            variant="light-primary"
                            badge
                            :src="require('@/assets/images/avatars/grumpy.jpg')"
                            class="badge-minimal"
                            badge-variant="success"
                          />
                          <b-avatar
                            v-else
                            size="30"
                            :variant="
                              item.updated_by == userName
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                            :text="
                              initials(
                                item.updated_by != null
                                  ? item.updated_by
                                  : item.created_by
                              )
                            "
                            class="badge-minimal"
                          />
                        </span>
                        {{ item.updated_by != null ? "Updated" : "Created" }}
                        {{ formatDatetime(item.updated_at) }}
                      </p>

                      <!-- toltip here -->
                      <b-tooltip :target="'disabled-wrapper' + item.id">{{
                        item.updated_by != null ? item.updated_by : item.created_by
                      }}</b-tooltip>
                    </template>
                    <template v-slot:item.subtotal="{ item }">
                      {{
                        (item.admin_fee_enabled
                          ? item.admin_fee_subtotal + item.subtotal
                          : item.subtotal
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </template>
                    <template v-slot:item.sqft="{ item }">
                      {{
                        (item.admin_fee_enabled
                          ? (item.admin_fee_subtotal + item.subtotal) /
                            quote.rentable_sqft
                          : item.subtotal / quote.rentable_sqft
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </template>
                    <template v-slot:item.change_order_number="{ item }">
                      <strong style="color: #6e6b7b">{{
                        quote.sf_opportunity_number + "-CO" + item.change_order_number
                      }}</strong>
                    </template>
                  </v-data-table>
                  <div v-else class="text-center">
                    <h3 class="text-muted" style="font-weight: 600">
                      No Change Orders to Show
                    </h3>
                    <p class="text-muted">
                      Create a change order to handle changes in project scope <br />after
                      a contract has been signed.
                    </p>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="3" class="mt-0 pt-1">
        <b-card v-if="quote.ccdc_signed" bg-variant="primary" text-variant="white">
          <div>
            <h5 class="text-white mb-1" style="letter-spacing: 0.6px">
              <strong>Current Project Totals</strong>
            </h5>
          </div>
          <b-row>
            <b-col cols="6">
              <label style="letter-spacing: 0.6px" class="text-white" for="totalprice"
                >TOTAL PRICE</label
              >
              <h3 class="text-white">
                {{
                  (
                    quote.active_tfr.quote_totals.project_total + approvedCOTotal
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </b-col>
            <b-col cols="6">
              <label style="letter-spacing: 0.6px" class="text-white" for="totalprice"
                >PRICE / SQFT</label
              >
              <h3 class="text-white">
                {{
                  (
                    (quote.active_tfr.quote_totals.project_total + approvedCOTotal) /
                    quote.rentable_sqft
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </h3>
            </b-col>
          </b-row>
          <div style="cursor: pointer" class="mt-1" @click="toFinance">
            <small class="text-white"> View Detailed Finance Breakdown </small>
          </div>
        </b-card>
        <b-card
          class="overflow-auto"
          :style="quote.ccdc_signed ? 'max-height: 51.7vh' : 'max-height: 73.2vh'"
        >
          <h5 class="mb-2"><strong>Activity log</strong></h5>
          <log-card :activity_logs="quote.activity_logs" />
        </b-card>
      </b-col>
      <!-- end header info -->
    </b-row>
    <!-- start update tfr modal -->
    <b-modal
      ref="tfr-change-active"
      id="tfr-change-active"
      v-model="update_tfr_modal"
      centered
      title="Replace Active Revision"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <update-active-tfr
        :won_tfr="completeTfr"
        @wonAction="updateActiveTfr"
        @closeAction="closeChangeActive"
      />
    </b-modal>
    <!-- end update tfr modal -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BFormRadioGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCard,
  BAlert,
  BAvatar,
  BTooltip,
  BBadge,
  BModal,
} from "bootstrap-vue";
import {
  UilRuler,
  UilSuitcase,
  UilCheck,
  UilCheckCircle,
  UilChartLine,
  UilRefresh,
  UilExclamationTriangle,
  UilSync,
  UilTimesCircle,
} from "@iconscout/vue-unicons";
import { VDataTable } from "vuetify/lib";
import moment from "moment";
import LogCard from "./LogCard.vue";
import UpdateActiveTfr from "./Modals/UpdateActiveTfr.vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    UilCheck,
    BDropdown,
    BDropdownItem,
    UilChartLine,
    UilRefresh,
    BCard,
    UilExclamationTriangle,
    BAlert,
    VDataTable,
    BAvatar,
    BTooltip,
    BBadge,
    BModal,
    LogCard,
    UpdateActiveTfr,
    UilCheckCircle,
    UilSync,
    BDropdownDivider,
    UilTimesCircle,
  },
  props: {
    address: {
      type: String,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: false,
    },
  },
  data() {
    return {
      userName: localStorage.getItem("name"),
      fields: [
        {
          text: "ID",
          value: "tfr",
        },
        {
          text: "QUOTE TOTAL",
          value: "quote_totals.project_total",
        },
        {
          text: "$/SQFT",
          value: "quote_totals.project_dollar_per_sqft",
        },
        {
          text: "STATUS",
          value: "tfr_status",
        },
        {
          text: "UPDATED",
          value: "updated_at",
        },
      ],
      fieldsCO: [
        {
          text: "ID",
          value: "change_order_number",
        },
        {
          text: "QUOTE TOTAL",
          value: "subtotal",
        },
        {
          text: "$/SQFT",
          value: "sqft",
        },
        {
          text: "STATUS",
          value: "status",
        },
        {
          text: "UPDATED",
          value: "updated_at",
        },
      ],
      tfrs: [],
      CO: [],
      update_tfr_modal: false,
      initLenght: 0,
      initCOLenght: 0,
      isAdmin: localStorage.getItem("isAdmin"),
    };
  },
  mounted() {
    this.updateBreadcrumb();
    this.initTfr();
    this.initCO();
  },
  computed: {
    completeTfr() {
      return Array.isArray(this.quote.tfrs)
        ? this.quote.tfrs.filter(
            (n) =>
              (n.tfr_status == "Quote in Progress" ||
                n.tfr_status == "Quote Completed") &&
              n.marked_as_active != true
          )
        : [];
    },
    approvedCOTotal() {
      let sum = 0;
      if (this.quote.active_change_orders.length > 0) {
        this.quote.active_change_orders.map((o) => {
          if (o.admin_fee_enabled) {
            sum += parseFloat(o.admin_fee_subtotal) + parseFloat(o.subtotal);
          } else {
            sum += o.subtotal;
          }
        });
      }
      return sum;
    },
    itemsLength() {
      return this.quote.tfrs.length;
    },
    itemsCOLength() {
      return this.quote.change_orders.length;
    },
    signedUpdate() {
      return this.quote.ccdc_signed;
    },
  },
  watch: {
    itemsLength(val, oldVal) {
      this.initTfr();
    },
    itemsCOLength(val, oldVal) {
      this.initCO();
    },
    signedUpdate(val, oldVal) {
      // this.updateBreadcrumb();
    },
  },
  methods: {
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[1].text = `${this.address}`;
      this.$route.meta.breadcrumb[2].active = true;
      if (this.quote.ccdc_signed) {
        this.$route.meta.breadcrumb[2].text = `Revisions`;
      } else {
        this.$route.meta.breadcrumb[2].text = `Revisions`;
      }
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    initTfr() {
      this.tfrs = this.quote.tfrs.filter((t) => t.marked_as_active != true);
      this.initLenght = this.tfrs.length;
    },
    initCO() {
      this.CO = this.quote.change_orders;
      this.initCOLenght = this.CO.length;
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date);
    },
    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
    CObadgeClass(status) {
      if (status == "Draft") {
        return "draft";
      } else if (status == "Approved") {
        return "approved";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Quote In Progress") {
        return "quote-in-progress";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      }
    },
    initials(text) {
      let initial;
      if (text != null) {
        initial = text
          .match(/(\b\S)?/g)
          .join("")
          .match(/(^\S|\S$)?/g)
          .join("")
          .toUpperCase();
      } else {
        initial = "N/A";
      }

      return initial;
    },
    closeChangeActive() {
      this.update_tfr_modal = false;
    },
    updateActiveTfr(data) {
      this.$emit("updateTfr", data);
      this.update_tfr_modal = false;
    },
    toRevisionPage(item, index, event) {
      // if (event.ctrlKey) {
      window.open(`/active/${this.$route.params.ActiveProjectId}/${item.id}`, "_blank");
      //   return;
      // }
      // if (event.metaKey) {
      //   window.open(`/active/${this.$route.params.ActiveProjectId}/${item.id}`, "_blank");
      //   return;
      // }
      // window.location.href = `/active/${this.$route.params.ActiveProjectId}/${item.id}`;
    },
    toCOPage(item, index, event) {
      // if (event.ctrlKey) {
      window.open(
        `/active/${this.$route.params.ActiveProjectId}/co/${item.id}`,
        "_blank"
      );
      //   return;
      // }
      // if (event.metaKey) {
      //   window.open(
      //     `/active/${this.$route.params.ActiveProjectId}/co/${item.id}`,
      //     "_blank"
      //   );
      //   return;
      // }
      // window.location.href = `/active/${this.$route.params.ActiveProjectId}/co/${item.id}`;
    },
    createTfr() {
      this.$emit("openTfrModal");
    },
    toFinance() {
      this.$router.push({ name: "Active Project Finance" });
    },
    revertSubmittedHandover() {
      this.$http
        .get(`/opportunities/${this.$route.params.ActiveProjectId}/unsubmit-for-handover`)
        .then((response) => {
          window.location.href = `/opportunities/${this.$route.params.ActiveProjectId}`;
        })
        .catch((error) => {
          console.log(error);
          this.showToast("danger", error);
        });
    },
    revertMarkedAsComplete() {
      this.$http
        .get(`/opportunities/${this.$route.params.ActiveProjectId}/unmark-complete`)
        .then((response) => {
          //window.location.href = `/opportunities/${this.$route.params.ActiveProjectId}`;
          this.quote.marked_as_complete=false
          this.$root.$emit("update-completed-alert", false);
        })
        .catch((error) => {
          console.log(error);
          this.showToast("danger", error);
        });
    },
    markAsCompleted() {
      this.$bvModal
        .msgBoxConfirm(
          "This will move the project to Completed Projects. It will be available to view but can no longer be edited.",
          {
            title: "Mark Project as Complete",
            hideHeaderClose: true,
            okTitle: "Mark as Complete",
            cancelTitle: "Cancel",
            centered: true,
            bodyClass: "p-1",
          }
        )
        .then((value) => {
          if (value == true) {
            this.$http
              .get(`/opportunities/${this.$route.params.ActiveProjectId}/complete`)
              .then((response) => {
                this.$root.$emit("update-completed-alert", true);
                this.showToast(
                  "success",
                  `This project has been moved to Completed Projects.`,
                  "Project marked as complete!"
                );
                this.quote.marked_as_complete=true
                //window.location.href = `/opportunities/${this.$route.params.ActiveProjectId}`;
              })
              .catch((error) => {
                console.log(error);
                this.showToast("danger", error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    markAsLost() {
      this.$bvModal
        .msgBoxConfirm(
          "Marking a project as lost will move it back to the Opportunities list.",
          {
            title: "Mark Project as Lost",
            hideHeaderClose: true,
            okTitle: "Mark as Lost",
            cancelTitle: "Cancel",
            centered: true,
            bodyClass: "p-1",
          }
        )
        .then((value) => {
          if (value == true) {
            this.$http
              .post(`/opportunities/${this.$route.params.ActiveProjectId}/lost`)
              .then((response) => {
                this.$emit('revertMarksigned')
                //this.$root.$emit("update-completed-alert", true);
                this.showToast(
                  "success",
                  `This project has been moved back to Opportunities.`,
                  "Project successfully marked as lost."
                );
                window.location.href =(`/opportunities/${this.$route.params.ActiveProjectId}`);
              })
              .catch((error) => {
                console.log(error);
                this.showToast("danger", error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.custom_btn {
  color:  rgba(92, 177, 231, 1) ;
  height: 38px;
  margin-left: 10px;
}
.label_tfr {
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #b9b9c3;
}
.list-overview {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.span-overview {
  flex: 0 0 60%;
  text-align: right;
}

.label-overview {
  font-weight: bold;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 14px;
  height: 48px;
}

.modal-padding-complete {
  padding: 0.8rem 1.4rem !important;
}
</style>
